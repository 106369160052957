import firebase from "firebase/app";

// Import needed firebase modules
import "firebase/auth";

// Firebase app config
var firebaseConfig = {
    apiKey: "AIzaSyBYzsJGB0vlwaexenlMM1BDX6cMnqIkQek",
    authDomain: "divergent-2f6a9.firebaseapp.com",
    projectId: "divergent-2f6a9",
    storageBucket: "divergent-2f6a9.appspot.com",
    messagingSenderId: "1061508578734",
    appId: "1:1061508578734:web:63b32d82531dbfca621c3f"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);