import { render, staticRenderFns } from "./WPCard.vue?vue&type=template&id=202e624d&scoped=true&"
import script from "./WPCard.vue?vue&type=script&lang=js&"
export * from "./WPCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "202e624d",
  null
  
)

export default component.exports