<template>
  <v-container class="dv-container">
    <h1 class="text-title">Bienvenue sur Divergent !</h1>
    <div>
      <h2 class="text-title">DIVERsité-Genre-EnseignemenT</h2>
      <p>DIVERGENT est un projet qui propose deux types d’actions pour sensibiliser les acteurs et actrices de la HES-SO à poser des petits pas dans la direction de l’intégration des défis liés au genre et à la diversité dans leurs missions.</p>

      <v-row>
        <v-col cols="12"  md="6">
          <router-link to="/dans-la-vie-de">
            <WPCard
                    title="Dans la vie de…"
                    subtitle="Le premier outil"
                    description="Le premier outil est une activité ludique et pédagogique qui s’inspire du jeu Un pas en avant. Il est mobilisé pour sensibiliser tant les étudiant·e·s, le personnel d’enseignement et de recherche que le personnel administratif et technique aux problématiques de la discrimination."
                    link="/dans-la-vie-de"
            ></WPCard>
          </router-link>
        </v-col>
        <v-col cols="12" md="6">
          <router-link to="/Quiz">
            <WPCard
                    subtitle="Le deuxième outil"
                    title="Gender and Diversity Index (GDI)"
                    description="destiné essentiellement aux enseignant-e-s, vise à̀ leur permettre d’autoévaluer leurs pratiques d’enseignement à l’aune du genre et de la diversité."
                    link="/Quiz">
            </WPCard>
          </router-link>
        </v-col>
      </v-row>
      <v-row align="center" justify-md="flex-end" justify-sm="center">
        <v-spacer class="d-none d-md-block"></v-spacer>
        <v-col md="6" class="no-gutters py-0">
          <img src="@/assets/HES-SO_logo.png" width="100" class="d-inline-block" align="center"/>
          <img src="@/assets/HETSL_logo.png" width="100" class="d-inline-block" align="center"/>
          <img src="@/assets/HEG_logo.png" width="250" class="d-inline-block" align="center"/>
        </v-col>
      </v-row>
      <!-- <Thumbnail :vignette='this.getThumbnail()' message="fjalgj"/> -->
    </div>
  </v-container>
</template>

<style scoped>
  p {
    padding-top: 15px;
  }
  h1 {
    font-size: 45px;
  }
  >>> .v-card {
    height: 100%;
    position: relative;
    padding-bottom: 50px;
  }
  >>> .v-card__actions {
    position: absolute;
    bottom: 0;
    margin-top: 30px;
  }
</style>

<script>
// @ is an alias to /src
//import Thumbnail from "@/components/Thumbnail";
import store from "@/store/index";
import WPCard from "../components/WPCard";

export default {
  name: 'Home',
  components: {
    WPCard,
    //Thumbnail
  },
  data() {
    return {
      thumbnail: '',
      text: '',
    }
  },
  methods: {
    getThumbnail() {
      console.log(store.getters.vignetteRandom);

      return store.getters.vignetteRandom;
    },
  },
}
</script>
<style scoped>
  a {
    text-decoration: none;
  }
</style>