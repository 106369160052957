<template>
    <v-container class="dv-container">
        <button @click="logout">Logout</button>
        <h1 class="pb-3">Admin</h1>
        <h2 v-if="results" class="pb-4">Nombre de participant(e)s : {{ results.length }}</h2>
        <v-data-table v-if="results"
                :headers="headers"
                :items="results"
                :items-per-page="5"
                class="elevation-1"
        ></v-data-table>
    </v-container>
</template>

<script>
    import firebase from "firebase";
    const db = firebase.firestore();

    export default {
        name: "Results",
        data () {
            return {
                results : [],
                headers: [
                    {text: 'Date', value: 'date'},
                    {
                        text: 'Réponses des participants',
                        align: 'start',
                        sortable: false,
                        value: 'answers',
                    },
                    { text: 'Score total (pions perdus)', value: 'score' },
                    { text: 'score Racisme', value: 'scoreRacisme' },
                    { text: 'score Sexisme', value: 'scoreSexisme' },
                    { text: 'score Capacitisme', value: 'scoreCapacitisme' },
                    { text: 'score Classe', value: 'scoreClasse' },
                ],
            }
        },
        created() {
            this.readResults();
        },
        methods : {
            logout() {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        alert('Successfully logged out');
                        this.$router.push('/');
                    })
                    .catch(error => {
                        alert(error.message);
                        this.$router.push('/');
                    });
            },
            readResults() {
                db.collection("results")
                    .get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            this.results.push({
                                id: doc.id,
                                date: doc.data().date,
                                answers: doc.data().answers,
                                score: doc.data().score,
                                scoreRacisme: doc.data().scoreRacisme,
                                scoreSexisme: doc.data().scoreSexisme,
                                scoreCapacitisme: doc.data().scoreCapacitisme,
                                scoreClasse: doc.data().scoreClasse
                            });
                            console.log(doc.id, " => ", doc.data());
                        });
                    })
                    .catch((error) => {
                        console.log("Error getting documents: ", error);
                    });
            }
        }
    }
</script>

<style scoped>
</style>