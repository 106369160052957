<template>
    <v-card
            class="mx-auto">
        <v-card-text>
            <div>{{ subtitle }}</div>
            <p class="display-1 text-orange">
                {{ title }}
            </p>
            <div>
                {{ description }}
            </div>
        </v-card-text>
        <v-card-actions>
            <router-link :to=link>
                <v-btn text>
                    <p class="dv-orange">{{ buttonText }}</p>
                </v-btn>
            </router-link>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "WPCard",
        props: {
            subtitle: String,
            title: String,
            description: String,
            link: String,
            buttonText : {
                type : String,
                default : "Découvrir"
            }
        },
    }
</script>

<style scoped>

</style>