<template>
    <v-container class="dv-container" style="padding-right: 0px">
        <v-row>
            <v-col cols="12" md="8">
                <h1 id="indication">Indications préalables sur les notices</h1>
                <p>
                    Les présentes notices, de même que l’outil « Gender and
                    Diversity Index » auquel elles sont rattachées, ont été
                    élaborés dans le cadre d’un appel à projet de la Haute École
                    Spécialisée de Suisse Occidentale (HES-SO), en conformité
                    avec sa politique-cadre à l’égard de la Diversité, selon
                    laquelle les hautes écoles qui la composent mettent en œuvre
                    des actions concrètes en leur sein aussi bien pour la
                    population estudiantine que pour le personnel dans
                    l’accomplissement de leurs missions. Or, la compréhension
                    même de la notion de « Diversité » ne va pas de soi, du
                    point de vue des sciences sociales et humaines.
                </p>
                <p>
                    En effet, portée par une grande partie de l’opinion
                    publique, omniprésente dans le débat public et dans de
                    nombreux domaines, que ce soit d’un point politique,
                    économique, médiatique ou encore social, au point d’en être
                    presque « naturalisée », l’existence même de la notion de «
                    diversité » — on parle souvent même de « la diversité » sans
                    qu’il ne soit plus nécessaire de préciser de quel type de
                    diversité il s’agit — ne semble plus à démontrer, encore
                    moins de discuter de son (in)évidence. Soit, on s’y oppose
                    parce que pensée et perçue comme négative, soit alors on
                    l’accepte, la reconnaît, la gère, la promeut parce pensée et
                    perçue comme positive (Adami, 2019).
                </p>
                <p>
                    Or, la Diversité fait encore débat en tant que notion
                    (contenu et frontières), en tant que processus (formes) et
                    en tant que disposition (finalités des politiques qui la
                    promeuvent ou s’y opposent, Bruna & Chauvet, 2018). Comme
                    aucune définition ne peut être proposée qui puisse faire
                    référence commune, – du reste, l’un de ses principaux
                    avantages est bien sa plasticité, qui permet à ses
                    utilisateurs et utilisatrices de la relier à une gamme
                    étendue de discours et de dispositifs –, la question qui se
                    pose est de savoir quelle conception de la Diversité
                    pourrait permettre de saisir ou approcher les principaux
                    enjeux qui se posent dans le cadre des institutions
                    d’enseignement de niveau tertiaire universitaire, comme la
                    HES-SO. Dans un tel contexte, trois conceptions de la
                    diversité semblent théoriquement intéressantes à rapporter
                    ici, sans en circonscrire forcément tout le contour de cette
                    notion. Il s’agit de celle de Bayad, Naffaki et Schmitt
                    (2007) pour qui la Diversité peut être pensée et perçue
                    comme la différence en termes de catégories sociales de «
                    race », de genre, de nationalité, d’origine, d’ethnie, de
                    niveau socioculturel ou socioéconomique. Il s’agit de
                    l’autre de celle de Beaumier et Lescarbeau (2001) pour qui
                    cette notion a trait aux caractéristiques se référant aux
                    différences de genres, de « race », d’origines ethniques,
                    d’âges, d’orientations sexuelles, de religions, de
                    professions, d’occupations, d’habiletés professionnelles, de
                    formation et de discipline qui différencient une personne à
                    l’autre ou un groupe d’un autre à l’intérieur d’un système
                    social. La troisième, qui regroupe et recoupe les deux
                    premières, relève de « l’intersectionnalité » (Bilge, 2009).
                    Cette approche permet de penser la Diversité en termes de
                    prise en compte des interrelations entre les différentes
                    catégories sociales susmentionnées dans la production, la
                    reproduction, le maintien, voire la transformation des
                    rapports sociaux et la réduction des inégalités sociales qui
                    les accompagnent.
                </p>
                <p>
                    De ce point de vue, et dans la ligne de la politique-cadre
                    de la HES-SO, la Diversité pourrait se concevoir comme un
                    ensemble de manières de penser, d’être et d’agir se situant
                    à l’interface du juridique (lutte contre les discriminations
                    liées aux stéréotypes en tous genres et les inégalités de
                    traitement), du politique (par l’adaptation des politiques
                    RH au principe de la Diversité, par la mise en place de
                    conditions propices à la réalisation de l’égalité des
                    chances entre tous les acteurs et toutes les actrices, dont
                    la promotion de l’équilibre social et individuel au sein de
                    ses composantes à travers la relève académique, la promotion
                    des femmes, l’hétérogénéité des âges ou encore l’ouverture
                    de postes sans distinction de genre, origine ou handicap) et
                    du « faire communauté » (par l’inclusion de la Diversité
                    dans les critères qualité de l’enseignement et de la
                    recherche).
                </p>
                <p>
                    Sans minimiser l'importance des différentes catégories
                    sociales pouvant être mises en avant sous le vocable de «
                    Diversité », la focale a été davantage mise dans le présent
                    projet sur les effets des rapports sociaux de genre et de «
                    race » et leur imbrication dans le contexte de la HES-SO, en
                    raison de nos champs d'expertise.
                </p>
                <p>
                    Enfin, il sied d’indiquer les recherches référencées dans ce
                    document ne s’inscrivent pas dans une visée d’exhaustivité,
                    loin s’en faut. Elles répondent à des choix théoriques
                    opérés par l’équipe du projet tenant compte de leurs champs
                    de recherche et de leurs disciplines et approches de base
                    (psychologie sociale, anthropologie, sociologie, études
                    genre et approches intersectionnelles). Or, comme chacun des
                    concepts présentés dans ces notices peut être appréhendé de
                    manière différente ou complémentaire de ces disciplines, le
                    lectorat intéressé à approfondir certains concepts ou types
                    de rapports sociaux pourra trouver d’autres sources à partir
                    des premières indications sélectives opérées ici.
                </p>
                <p>
                    Adami, H. (2019). La logomachie sur la notion de “diversité”
                    : quelques éléments d’analyse. In K. Sy, P. Stalder, J.
                    Veillette & A. Gohard-Radenkovic (Éds), De la diversité
                    fantasmée aux effets de réalités : discours et pratiques
                    (pp. 73-87). L’Harmattan.
                </p>
                <p>
                    Bayad, M., Naffaki, H., & Schmitt, C. (2007). L'équipe
                    entrepreneuriale : rôle de la diversité dans le processus
                    entrepreneurial. 5e Congrès de l’Académie de
                    l’Entrepreneuriat. Canada.
                </p>
                <p>
                    Beaumier, M. & Lescarbeau, R. (2001), La gestion de la
                    diversité dans les équipes multidisciplinaires. Chronique
                    Méthodes, 5, 153-184.
                </p>
                <p>
                    Bilge, S. (2009). Théorisations féministes de
                    l’intersectionnalité. Diogène, 225, 70-88.
                </p>
                <p>
                    Bruna, M. G. & Chauvet, M. (2013). La diversité, un levier
                    de performance : plaidoyer pour un management innovateur et
                    créatif. Management international / International Management
                    / Gestiòn Internacional, 17, 70–84.
                    https://doi.org/10.7202/1015813ar
                </p>

                <h1 id="rapportSociaux">Rapports sociaux</h1>
                <figure class="quote">
                    <blockquote>
                        « La notion de rapports sociaux indique d’emblée que le
                        monde social est une combinaison d’unités et de
                        divisions, de cohésion et de conflits. Il met l’accent,
                        en même temps, sur ce qui réunit les êtres humains
                        vivant en société et sur ce qui les divise, les oppose,
                        les déchire. Il permet de penser la société comme étant
                        à la fois unité et lutte, dialectiquement : unité
                        dans/par/contre la lutte et la lutte dans/par/contre
                        l’unité. Alors que la notion de lien social met
                        unilatéralement l’accent sur ce qui lie et réunit les
                        êtres humains entre eux, au détriment de ce qui les
                        divise et les oppose, dans leur unité même. Le concept
                        de rapport social englobe, par conséquent, celui de lien
                        social, il permet de penser le lien et l’opposition,
                        alors que ce dernier empêche de penser le conflit »
                    </blockquote>
                    <figcaption>
                        (Pfefferkorn & Pereira, 2013: 17-18)
                    </figcaption>
                </figure>
                <br />
                <figure class="quote">
                    <blockquote>
                        « Le concept de rapport social permet de
                        désubstantialiser tous les ‘objets’ sociaux. Construit
                        par Marx principalement par rapport aux classes, il peut
                        être (et a été) étendu à bien d’autres groupements, les
                        sexes ou des regroupements d’individus en fonction
                        d’autres critères, par exemple leur origine, réelle ou
                        supposée, leur ‘race’, leur appartenance religieuse,
                        leur lieu d’habitation, leurs préférences sexuelles,
                        leurs pratiques culturelles, etc. »
                    </blockquote>
                    <figcaption>(Pfefferkorn & Pereira, 2013: 23)</figcaption>
                </figure>
                <br />
                <h4>Référence</h4>
                <p>
                    Pfefferkorn, R. & Pereira I. (2013). Des rapports de
                    domination aux luttes d'émancipation. Entretien avec Roland
                    Pfefferkom. Propos recueillis par Irène Pereira. Raison
                    présente, 185(1), 17-26.
                </p>

                <h2 id="intersectionnalite">
                    Intersectionnalité – approche intersectionnelle
                </h2>
                <figure class="quote">
                    <blockquote>
                        Notion aujourd’hui incontournable tant se sont
                        multipliés les travaux scientifiques qui s’y réfèrent et
                        les politiques publiques ou les recommandations
                        internationales qui s’en réclament, l’intersectionnalité
                        est d’abord l’héritière des débats portés dans un
                        contexte militant par les féministes nord-américaines –
                        plus particulièrement les féministes
                        africaines-américaines et le courant black feminism.
                        Dans sa prise de position de 1977, le Combahee River
                        Collective critique en effet le « biais blanc de classe
                        moyenne » du féminisme. Il introduit alors la question
                        de la représentation politique de celles pour lesquelles
                        la domination subie articule plusieurs rapports de
                        pouvoir. Il pointe par conséquent le fait que les femmes
                        blanches qui sont alors leaders dans les groupes
                        féministes occupent en fait une position de domination.
                        De même, la critique black feminist va mettre en lumière
                        comment les hommes noirs sont également en position de
                        dominants dans les mouvements antiracistes. En d’autres
                        termes, être femme et noire induit une domination subie
                        autre que celle éprouvée par les femmes blanches ou par
                        les hommes noirs.
                    </blockquote>
                    <figcaption>(Mazouz, 2019)</figcaption>
                </figure>
                <br />
                <h4>Référence</h4>
                <p>
                    Mazouz, Sarah (2019-05-07), Intersectionnalité. Anthropen.
                    <a
                        href="https://doi.org/10.17184/eac.anthropen.111"
                        target="blank"
                        >https://doi.org/10.17184/eac.anthropen.111</a
                    >
                </p>
                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        Bereni, Laure, & Jaunait, Alexandre. (2009). Usages de
                        la diversité. Raisons politiques (3), 5-9.
                    </li>
                    <li>
                        Bruna, M., & Chauvet, M. (2013). La diversité, un levier
                        de performance: plaidoyer pour un management innovateur
                        et créatif. Management international/International
                        Management/Gestiòn Internacional, 17, 70-84.
                    </li>
                    <li>
                        Crenshaw Williams, Kimberlé. (2005). Cartographie des
                        marges: Intersectionnalité, politique de l'identité et
                        violences contre les femmes de couleur. Cahiers du
                        genre, 39, 52-82.
                    </li>
                    <li>
                        Kergoat, D. (2011). Comprendre les rapports sociaux.
                        Raison présente, 178(1), 11-21.
                    </li>
                    <li>
                        Rodó-de-Zárate, Maria. (2014). Developing geographies of
                        intersectionality with Relief Maps: reflections from
                        youth research in Manresa, Catalonia. Gender, place &
                        culture, 21(8), 925-944.
                    </li>
                    <li>
                        Masclet, O. (2012). Sociologie de la diversité et des
                        discriminations. Armand Colin.
                    </li>
                </ul>

                <h4>Ressources</h4>
                <ul>
                    <li>
                        <a
                            href="https://hesav.ch/recherche/axes-de-recherche/axe-pratiques-professionnelles-et-interprofessionnelles/co-construire-une-pedagogie-de-l-egalite-dans-la-hes-so/#:~:text=Le%20projet%20%C2%AB%20Co%2Dconstruire%20une,la%20diversit%C3%A9%20dans%20l'enseignement"
                            target="blank"
                            >https://hesav.ch/recherche/axes-de-recherche/axe-pratiques-professionnelles-et-interprofessionnelles/co-construire-une-pedagogie-de-l-egalite-dans-la-hes-so/</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://www.unige.ch/fapse/grire/"
                            target="blank"
                            >https://www.unige.ch/fapse/grire/</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://www.unige.ch/droit/lawclinic/fr/publications/brochures/"
                            target="blank"
                            >https://www.unige.ch/droit/lawclinic/fr/publications/brochures/</a
                        >
                    </li>
                    <li>
                        <a
                            href="http://svt-egalite.fr/index.php/egalitheque"
                            target="blank"
                            >http://svt-egalite.fr/index.php/egalitheque</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://www.cufay.fr/les-pedagogies-emancipatrices-9782365122016.html#product-tabs"
                            target="blank"
                            >https://www.cufay.fr/les-pedagogies-emancipatrices-9782365122016.html#product-tabs</a
                        >
                    </li>
                </ul>

                <h3 id="stereotypes">Stéréotypes</h3>

                <p>
                    Le terme de stéréotypes a été inventé par l’imprimeur
                    français Didot en 1798, et son sens actuel « d’images dans
                    la tête », qui permettent de simplifier, filtrer et
                    organiser la réalité environnementale trop vaste et souvent
                    trop complexe est dû au journaliste Lippmann (1922), notant
                    que ce que chacun·e sait ou croit savoir n’est généralement
                    pas basé sur une connaissance directe et fine mais très
                    souvent sur une image produite et donnée par lui ou elle.
                    Autrement dit, il n’existe pas de « vérité universelle » sur
                    le monde social sur laquelle les gens pourraient s’appuyer.
                    En revanche, pour le meilleur et pour le pire, les gens
                    naviguent dans leur monde social en s’aidant de ces images
                    dans leur tête.
                </p>
                <p>
                    Le terme « stéréotype » est aussi bien utilisé dans le
                    langage scientifique que dans le langage profane (dans
                    lequel une certaine confusion est souvent faite avec une
                    notion connexe qu’est le préjugé). En dépit de nombreuses
                    controverses ayant présidé à son élaboration en tant
                    qu’objet de recherche, la définition la plus simple du
                    stéréotype sur laquelle la plupart des auteurs s’accordent
                    aujourd’hui, particulièrement dans le champ de la
                    psychologie sociale est celle de Judd et Park (1993) selon
                    laquelle un stéréotype est l’ensemble des croyances d’un
                    individu relatives aux caractéristiques ou aux attributs
                    d’un groupe ».
                </p>
                <p>
                    Cette brève définition suggère que les stéréotypes n’ont pas
                    besoin d’être négatifs ou positifs, pas plus qu’ils n’ont
                    besoin d’être exacts ou faux pour exister ; ils ne doivent
                    que servir de caractéristiques ou d’attributs distinguant un
                    groupe social d’un autre. En fait, comme le suggèrent
                    Leyens, Yzerbyt et Schadron (1996, p. 100) :
                    <q
                        >« les stéréotypes sont des outils si faciles à utiliser
                        que presqu’aucune connaissance des groupes n’est
                        nécessaire pour qu’ils apparaissent. Ouï-dire et séries
                        télévisées suffisent ».</q
                    >
                </p>
                <p>
                    Le stéréotype fonctionne davantage à la manière d’un écran à
                    travers lequel nous voyons la réalité : de cette façon, les
                    seules informations ne suffisent pas à les ébranler, dans la
                    mesure où ils modifient notre perception de la réalité. Les
                    individus ont souvent alors tendance à traiter en priorité
                    les informations qui confirment leurs croyances
                    stéréotypées, négligeant ou « oubliant » toutes celles qui
                    les infirment. Les stéréotypes sont par conséquent très
                    difficiles à combattre. S’ils sont contredits par les faits,
                    les gens préfèrent nier les faits plutôt que de les remettre
                    en question.
                </p>
                <p>
                    Si l’étude des stéréotypes a été et continue d’être une
                    préoccupation centrale pour les sciences sociales et
                    humaines, c’est qu’ils sont pensés comme influençant
                    fortement les relations que les individus membres de groupes
                    dominants entretiennent avec les individus appartenant aux
                    groupes dominés. Dans cette optique, de nombreuses
                    recherches ont démontré que les stéréotypes entraînent la
                    discrimination, influencent les jugements et les impressions
                    que les individus se forment d’autrui la manière dont sont
                    perçus et se perçoivent les individus, plus récemment les
                    performances scolaires.
                </p>
                <p>
                    Dans le domaine académique, de nombreuses recherches
                    conduites depuis les années 90 ont montré que les
                    stéréotypes fonctionnent comme une sorte de prophétie
                    auto-réalisatrice, en ce sens que leur saillance dans un
                    contexte particulier est susceptible d’interférer avec le
                    fonctionnement intellectuel normal des personnes et les
                    amener à adopter des comportements qui viennent les
                    confirmer. Ainsi, par exemple, il a été montré que la peur
                    ressentie par certaines femmes en cas de mauvaises notes
                    dans une épreuve de mathématiques, de confirmer aux yeux des
                    autres et à leurs propres yeux le stéréotype négatif selon
                    lequel les femmes seraient « moins douées en mathématiques
                    que les hommes » (menace dans l’air) génère une pression sur
                    elles qui entraîne par ricochet une baisse de leurs
                    performance ; celle-ci venant confirmer le stéréotype en
                    question (phénomène connu sous le nom de menace du
                    stéréotype, Konan, Chatard, Selimbegović, Mugny, & Moraru,
                    2011 ; Steele & Aronons, 1995). A contrario, cette même
                    croyance, également partagées par les hommes, peut entraîner
                    une amélioration de leur performance (phénomène connu sous
                    le nom d’effet ascenseur du stéréotype, Chatard,
                    Selimbegović, Konan, & Mugny, 2008 ; Watson & Cohen, 2003 ).
                </p>
                <h4>Ressources :</h4>
                <ul>
                    <li>
                        Chatard, A., Selimbegovic, L., Konan, N. P., & Mugny, G.
                        (2008). Performance boosts in the classroom: Stereotype
                        endorsement and prejudice moderate stereotype lift.
                        Journal of Experimental Social Psychology, 44,
                        1421-1424.
                    </li>
                    <li>
                        Judd, C. M. & Park, B. (1993): Definition and assessment
                        of accuracy in social stereotypes. Psychological Review,
                        100, p. 109-128.
                    </li>
                    <li>
                        Konan, N. P., Chatard, A., Selimbegović, L., Mugny, G.,
                        & Moraru, A. (2011). Deflecting stereotype threat by
                        downward social comparison: When comparison with
                        immigrants boosts the performance of stigmatized native
                        students. Social Justice Research, 24, 191-205.
                    </li>
                    <li>
                        Leyens, J.-Ph, Yzerbyt, V., & Schadron, G. (1996).
                        Stéréotypes et cognition sociale. Mardaga
                    </li>
                    <li>
                        Lippmann, W. (1922). Public opinion. Harcourt, Brace
                        (Rééd.: 1965, NY: Free Press).
                    </li>
                    <li>
                        Steele, C. M. & Aronson, J. (1995). Stereotype threat
                        and the intellectual test performance of African
                        americans. Journal of Personality and Social Psychology
                        69, 797–811.
                    </li>
                    <li>
                        Walton, G. M., & Cohen, G. L. (2003). Stereotype lift.
                        Journal of Experimental Social Psychology, 39, 456-467.
                    </li>
                </ul>
                <h4>Pour aller plus loin</h4>
                <p>
                    <a
                        href="http://svt-egalite.fr/index.php/mecanismes/effet-pygmalion"
                        target="blank"
                        >http://svt-egalite.fr/index.php/mecanismes/effet-pygmalion</a
                    >
                </p>
                <p>
                    <a
                        href="http://svt-egalite.fr/index.php/mecanismes/la-menace-du-stereotype"
                        target="blank"
                        >http://svt-egalite.fr/index.php/mecanismes/la-menace-du-stereotype</a
                    >
                </p>

                <h3 id="prejuges">Préjugés</h3>
                <p>
                    Là où se trouvent les stéréotypes, les préjugés ne sont pas
                    loin derrière. Les préjugés mettent en jeu un pré-jugement,
                    c’est-à-dire une (pré)disposition, le plus souvent négative,
                    concernant un groupe ou ses membres, sans nécessairement
                    avoir connaissance du groupe en question. Dans son sens
                    psychosocial, les préjugés ne sont pas seulement
                    l’expression d’une opinion ou d’une croyance, mais bien une
                    attitude, avec tout ce qu’elle comporte comme évaluations,
                    sentiments, ou émotions tels que le mépris, la haine ou le
                    dégoût.
                </p>
                <p>
                    Le préjugé est généralement considéré comme la
                    correspondance affective du stéréotype tandis que celui-ci
                    se définit comme sa dimension cognitive. Fischer (2000, p.
                    113) le définit comme
                    <q
                        >« une attitude comportant une dimension évaluative,
                        souvent négative, à l’égard de types de personnes ou de
                        groupes, en fonction de leur appartenance sociale. C’est
                        donc une disposition acquise dont le but est d’établir
                        une différenciation sociale ».
                    </q>
                </p>
                <p>
                    Les préjugés font donc référence aux attitudes défavorables
                    ou aux réactions affectives négatives qu’un individu
                    entretient à l’égard de certains groupes sociaux auxquels il
                    n’appartient pas.
                </p>

                <h4>Référence</h4>
                <p>
                    Fischer, G.-N. (2000). Les concepts fondamentaux de la
                    psychologie sociale. Dunod.
                </p>

                <h3 id="discimi">Discrimination</h3>
                <p>
                    Par discrimination, on entend souvent un comportement
                    conduisant à désavantager ou à traiter de façon inégalitaire
                    certaines personnes en raison de leur appartenance groupale
                    (Bourhis, & Leyens, 1999). La discrimination est
                    conceptualisée comme un comportement injuste envers les
                    membres d’un groupe. Généralement, ce comportement résulte
                    d’une distinction entre les membres de l’endogroupe — le
                    groupe d’appartenance du sujet — et de l’exogroupe — le
                    groupe saillant dans la situation auquel le sujet
                    n’appartient pas — uniquement basée sur l’appartenance
                    groupale, avantageant les membres de l’endogroupe et/ou
                    désavantageant les membres de l’exogroupe. La discrimination
                    renvoie donc à un acte intentionnel et représente
                    l’expression comportementale d’une attitude défavorable
                    envers un groupe particulier.
                </p>
                <p>
                    La discrimination peut être directe ou indirecte. La
                    discrimination directe se caractérise par la volonté
                    délibérée de faire subir une discrimination à une personne
                    ou un groupe. La discrimination indirecte a lieu lorsqu’une
                    disposition, un critère ou une pratique apparemment neutre
                    placent de facto les représentants d’un groupe particulier
                    en situation de désavantage par rapport aux autres. De
                    telles règles, neutres en apparence, peuvent en fait
                    désavantager de manière disproportionnée les membres de
                    certains groupes sociaux. La discrimination directe et la
                    discrimination indirecte sont toutes deux interdites par les
                    instruments en matière de droits de l’homme. Si la
                    discrimination indirecte est plus répandue et plus difficile
                    à prouver que la discrimination directe, c’est parce qu’elle
                    est moins visible (parce qu’interdite).
                </p>

                <h4>Référence</h4>
                <p>
                    Bourhis, R.Y., & Leyens, J.-P. (1999) (Eds.). Stéréotypes,
                    discrimination et relations intergroupes. Mardaga.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        Angeli, V. (2020). Du plancher pourri au plafond de
                        verre du monde du travail. Dans : Omar Slaouti éd.,
                        Racismes de France (pp. 101-114). Paris: La Découverte.
                    </li>
                    <li>
                        Berset, A., Weygold, S.-A., Crevoisier, O., & Hainard,
                        F. (1999). Qualification-déqualification
                        professionnelles des immigrants. Approche théorique.
                        Revue européenne des migrations internationales, 15,
                        87-100.
                    </li>
                    <li>
                        Goguikian Ratcliff, B., Bolzman, C. & Gakuma, T.-O.
                        (2014). Déqualification des femmes migrantes en Suisse :
                        mécanismes sous-jacents et effets psychologiques.
                        Alterstice, 4, 63‐76.
                    </li>
                    <li>
                        Laufer, J. (2003). Entre égalité et inégalités: les
                        droits des femmes dans la sphère professionnelle.
                        L'Année sociologique, 53(1), 143-173.
                    </li>
                    <li>
                        Lépinard, Eléonore (2020).
                        <a
                            href="https://tarage.noblogs.org/lhumour-oppressif-eleonore-lepinard-denis-colombi/"
                            target="blank"
                            >https://tarage.noblogs.org/lhumour-oppressif-eleonore-lepinard-denis-colombi/</a
                        >
                    </li>
                    <li>
                        Martinot D., Toczek M.-C., & Brunot S. (2003).
                        Performances scolaires des élèves et appartenance
                        ethnique (majoritaire vs. minoritaire) de leurs modèles
                        professionnels. Revue Française de Pédagogie, 145,
                        77-86.
                    </li>
                    <li>
                        Marx D. M., & Roman J. S. (2002). Female role models:
                        Protecting women’s math test performance. Personality
                        and Social Psychology Bulletin, 28, 1183-1193.
                    </li>
                </ul>

                <h4>Ressources</h4>
                <p>
                    BAO :
                    <a
                        href="https://bao-genre.hes-so.ch/fr/plafond-verre-7840.html"
                        target="blank"
                        >https://bao-genre.hes-so.ch/fr/plafond-verre-7840.html</a
                    >
                </p>

                <h2 id="inclusif">Langage inclusif</h2>
                <figure class="quote">
                    <blockquote>
                        « La langue est une construction sociale, culturelle.
                        Elle n’est ni figée et ni soumise à des règles absolues,
                        ‘naturelles’. Elle reflète, à travers son vocabulaire et
                        sa grammaire les conceptions d’une société à une époque
                        donnée, ainsi que les rapports de domination qui s’y
                        jouent. La langue française témoigne ainsi de la
                        domination masculine et véhicule également des
                        représentations racistes, homophobes, de nature à
                        discriminer les personnes vivant des handicaps et les
                        trans, à exclure les personnes intersexes, etc. Cette
                        question n’est évidemment pas exclusivement liée à
                        l’école, mais l’école contribue activement à maintenir
                        des représentations discriminantes par ce biais. Il est
                        relativement simple d’agir sur nos pratiques en matière
                        de langue, cela contribue à modifier les
                        représentations, à faciliter la prise de conscience des
                        mécanismes de domination et à les enrayer. Cela concerne
                        aussi bien la rédaction d’un énoncé ou d’une activité,
                        que les interactions avec les élèves ou la transmission
                        d’un cours. »
                    </blockquote>
                    <figcaption>(SVT Égalité, 2016)</figcaption>
                </figure>
                <br />
                <p>
                    De nombreuses ressources portent sur des techniques de
                    rédaction favorisant des pratiques égalitaires, notamment
                    sur un langage non sexiste
                    <q>
                        « où le masculin ne l’emporte pas systématiquement sur
                        le féminin, un langage qui ne rend pas systématiquement
                        les femmes invisibles »
                    </q>
                    (Chaponnière & Ricci Lempen, 2012, p.27), mais aussi un
                    langage qui éviterait de reproduire d’autres rapports
                    sociaux, tels que le racisme, l’hétérocentrisme, la
                    cisnormativité, le capacitisme.
                </p>

                <h4>Référence</h4>
                <p>
                    Chaponnière, M. & Ricci, Lempen, S. (2012). Tu vois le genre
                    ? Débats féministes contemporains. D’en bas Editions.
                </p>
                <p>
                    SVT Égalité. (2016)<a
                        href="http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-sexisme"
                        target="blank"
                        >http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-sexisme</a
                    >
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Abbou, Julie, Arnold, Aron, Candea, Maria, & Marignier,
                    Noémie. (2018). Qui a peur de l’écriture inclusive? Entre
                    délire eschatologique et peur d’émasculation Entretien.
                    Semen. Revue de sémio-linguistique des textes et
                    discours(44). URL :
                    <a href="https://journals.openedition.org/semen/10800" target="blank">https://journals.openedition.org/semen/10800 </a>
                </p>
                <p>
                    Viennot, É., Haddad, R., & Sebagh, C. (2018). Le langage
                    inclusif: pourquoi, comment. Éditions iXe. (Informations sur
                    <a href="#" target="blank">https://www.babelio.com/livres/Viennot-Le-langage-inclusif-Pourquoi-Comment/1113868</a>)
                </p>

                <h4>Ressources</h4>

                <ul>
                    <li>
                        <a
                            href="https://www.hes-so.ch/fr/communication-inclusive-6801.html"
                            target="blank"
                            >https://www.hes-so.ch/fr/communication-inclusive-6801.html</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://www.unil.ch/egalite/files/live/sites/egalite/files/Egalite_UNIL/Activit%c3%a9s%20et%20soutien/R%c3%a9daction%20%c3%a9galitaire/feuillet_Langage%20epice%cc%80ne_124x291.pdf"
                            target="blank"
                            >https://www.unil.ch/egalite/files/live/sites/egalite/files/Egalite_UNIL/Activit%c3%a9s%20et%20soutien/R%c3%a9daction%20%c3%a9galitaire/feuillet_Langage%20epice%cc%80ne_124x291.pdf
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.gendercampus.ch/de/blog/post/ecriture-inclusive-ou-francais-inclusif/"
                            target="blank"
                            >https://www.gendercampus.ch/de/blog/post/ecriture-inclusive-ou-francais-inclusif/
                        </a>
                    </li>
                    <li>
                        <a href="https://www.tgns.ch/fr/medias/guide-linguistique/" target="blank">https://www.tgns.ch/fr/medias/guide-linguistique/
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ajlgbt.info/informer-sans-discriminer/" target="blank">https://www.ajlgbt.info/informer-sans-discriminer/
                        </a>
                    </li>
                    <li>
                        <a href="https://libraryguides.mcgill.ca/ecritureinclusive" target="blank">https://libraryguides.mcgill.ca/ecritureinclusive
                        </a>
                    </li>
                    <li>
                        <a href="http://www.2e-observatoire.com/downloads/livres/brochure7.pdf" target="blank">http://www.2e-observatoire.com/downloads/livres/brochure7.pdf</a
                        >
                    </li>
                    <li><a href="https://www.youtube.com/watch?v=IsEWKuh-uvY" target="blank">https://www.youtube.com/watch?v=IsEWKuh-uvY </a></li>
                    <li><a href="https://www.youtube.com/watch?v=2NFWHVBfsY4" target="blank">https://www.youtube.com/watch?v=2NFWHVBfsY4 </a></li>
                    <li><a href="https://www.youtube.com/watch?v=TN1PS9qGCkI" target="blank">https://www.youtube.com/watch?v=TN1PS9qGCkI</a></li>

                    <li>
                        <a href="https://www.unil.ch/egalite/files/live/sites/egalite/files/Egalite_UNIL/Activit%c3%a9s%20et%20soutien/R%c3%a9daction%20%c3%a9galitaire/feuillet_Langage%20epice%cc%80ne_124x291.pdf" target="blank">https://www.unil.ch/egalite/files/live/sites/egalite/files/Egalite_UNIL/Activit%c3%a9s%20et%20soutien/R%c3%a9daction%20%c3%a9galitaire/feuillet_Langage%20epice%cc%80ne_124x291.pdf</a
                        >
                    </li>
                    <li>
                        <a href="https://www.gendercampus.ch/de/blog/post/ecriture-inclusive-ou-francais-inclusif/" target="blank">https://www.gendercampus.ch/de/blog/post/ecriture-inclusive-ou-francais-inclusif/</a
                        >
                    </li>
                </ul>

                <h5>Racisme dans la langue</h5>
                <ul>
                    <li>
                        <a href="http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-racisme" target="blank">http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-racisme
                        </a>
                    </li>
                </ul>

                <h5>Capacitisme dans la langue</h5>
                <ul>
                    <li>
                        <a href="https://www.info-handicap.ch/fiches/communication/communication-orale-et-corporelle/autres-communications-alternatives/2735-langage-simplifie-facile-a-lire-et-a-comprendre-falc.html" target="blank">https://www.info-handicap.ch/fiches/communication/communication-orale-et-corporelle/autres-communications-alternatives/2735-langage-simplifie-facile-a-lire-et-a-comprendre-falc.html</a
                        >
                    </li>
                    <li>
                        <a href="http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-validisme" target="blank">http://svt-egalite.fr/index.php/mecanismes/langue-loin-d-etre-neutre/la-langue-et-le-validisme</a
                        >
                    </li>
                </ul>

                <h2 id="constructionConnaissance">
                    Construction des sciences / art / connaissance
                </h2>

                <p>
                    Dès leur développement dans les années 1970-1980, les études
                    féministes ont montré que la connaissance scientifique est
                    située, c’est à dire qu’elle n’est jamais neutre. Rompant
                    avec une épistémologie traditionnelle positiviste d’un côté
                    et, de l’autre, avec une épistémologie postmoderne prônant
                    la relativité de toute connaissance, la posture située
                    problématise la relation entre sujet et objet de
                    connaissance et montre que les normes de l’objectivité
                    scientifique sont déterminées par des positions
                    structurelles, matériellement et socialement situées
                    (Haraway, 1988). Cette posture propose en outre de
                    s’interroger sur les pratiques de production des savoirs et
                    sur les conséquences de ces productions, puisque ces
                    dernières participent à la construction de la réalité.
                </p>
                <p>
                    Ajoutons que sous cette perspective, le discours
                    scientifique est un discours parmi d’autres, qui présente
                    ses propres spécificités (en particulier le principe de
                    mettre au jour ses propres conditions de production, au
                    contraire des faits sociaux naturalisés). Sur le plan de la
                    critique sociale ou des luttes contre les dominations,
                    d’autres types de discours peuvent être produits entendus.
                    Prenons l’exemple des discours féministes : comme le relève
                    Raewyn Connell (Martin, 2015, p. 111),
                    <q
                        >« une immense part [du travail de luttes sociales et
                        culturelles] est réalisé dans des formes non académiques
                        ; dans l’histoire féministe, les essais politiques de
                        He-Yin-Zhen (en Chine) et les lettres de Kartini (à
                        Java) en sont des exemples remarquables. La relation
                        entre la société aborigène et la terre en Australie est
                        classiquement exprimée dans l’art visuel, spécialement
                        dans la peinture ».</q
                    >
                    De même (mais les exemples sont nombreux), Kimberley
                    Springer (2008), professeure de littérature à l’Ohio State
                    university, suggère aux éducateurs et éducatrices qui
                    travaillent avec des jeunes des ghettos qui ne liront pas
                    les écrits féministes d’utiliser la musique comme hip hop,
                    rap, neosoul, etc. dans des versions pas sexistes qui
                    permettent de discuter des rapports sociaux de sexe et de
                    race.
                </p>

                <h4>Référence</h4>
                <p>
                    Haraway, Dona. (1988). Situated Knowledge : The Science
                    Question in Feminism and the Privilege of Partial
                    Perspective. Feminist Studies, 14, 575-599.
                </p>
                <p>
                    Raewyn, C. & Pearse, R. (2015). Gender. In World perspective
                    (3rd Edition). Polity Press.
                </p>
                <p>
                    Springer, K. (2008). Une troisième vague du féminisme Noir ?
                    In Elsa Dorlin (Ed.), Black feminism. Anthologie du
                    féminisme africain-américain, 1975-2000 (pp. 221-249).
                    L'Harmattan.
                </p>

                <h1 id="rapportSocialRace">Rapport social de race</h1>

                <figure class="quote">
                    <blockquote>
                        « Pour nos contemporains ni l’existence, ni le nombre,
                        ni les délimitations entre les ‘races’ ne bénéficient
                        plus d’aucune évidence, mais les noms de la race
                        continuent de fonctionner dans l’identification de
                        différences ethniques et culturelles. On continue de
                        parler d’‘Européens’, d’’Orientaux’, d’’Arabes’, de
                        ‘Noirs’ ou d’’Africains’, etc. Plus que jamais peut-être
                        le principe de la race ou de la ‘racialisation’ s’impose
                        socialement et culturellement, en particulier comme
                        principe généalogique, et de représentations qui
                        rapportent à l’origine et à la descendance des
                        ‘mentalités’ ou des ‘aptitudes’ individuelles et
                        collectives supposées. »
                    </blockquote>
                    <figcaption>(Balibar, 2007, p. 163)</figcaption>
                </figure>
                <br />
                <h4>Référence</h4>
                <p>
                    Balibar, Etienne. (2007). Le retour de la race. Mouvements.
                    Mouvements, 50, 162-171.
                </p>

                <h2 id="racisme">Racisme</h2>
                <figure class="quote">
                    <blockquote>
                        « On entend par racisme une idéologie qui classe les
                        personnes dans des groupes, sur la base de différences
                        réelles ou imaginaires (ethnie, nationalité, religion,
                        etc.) jugées immuables. Les tenants du racisme
                        prétextent l’appartenance des personnes à un groupe en
                        leur attribuant une origine pseudo-biologique ou
                        culturelle commune. Les tenants du racisme valorisent
                        les différences - réelles ou imaginaires - pour
                        justifier des inégalités entre des groupes ou encore des
                        privilèges. »
                    </blockquote>
                    <figcaption>
                        (Office fédéral de la Statistique, 2017-2018)
                    </figcaption>
                </figure>
                <br />
                <p>
                    Pour les sciences sociales et humaines (Memmi, 1982 ; White
                    & Kite, 2013), le racisme procède de trois mécanismes
                    consubstantiels : la catégorisation, la hiérarchisation et
                    la discrimination. Si le racisme prend racine dans la
                    perception première d’une différence telle que la couleur de
                    la peau, les pratiques quotidiennes, religieuses,
                    alimentaires, vestimentaires, linguistiques, etc., c’est
                    davantage l’ensembles des significations, interprétations,
                    représentations (puisant souvent dans des registres
                    biologiques, évolutionnistes ou culturalistes) que les
                    individus donnent à ces différences qui composent son
                    essence.
                    <q
                        >« Les catégories ou ‘invariants’ du racisme se sont
                        progressivement déplacés d’une division de l’humanité
                        entre plusieurs sous-espèces à une idée de différence
                        culturelle naturalisée, et simultanément d’altérité ou
                        d’irréductibilité de l’autre, vers un schème d’exclusion
                        intérieure, qui vise tous ceux qu’une société ne peut
                        éliminer – sauf à retrouver des programmes de
                        ‘purification ethnique’ et de ‘solution finale’ – mais
                        que pour des raisons de culture, d’histoire,
                        d’organisation du travail, de généalogie, elle s’efforce
                        de mettre à part » </q
                    >(Balibar, 2007, p. 169).
                </p>
                <h4>Référence</h4>
                <p>
                    Balibar, E. (2007). Le retour de la race. Mouvements, 50,
                    162-171.
                </p>
                <p>Memmi, A. (1982). Le racisme. Gallimard.</p>
                <p>
                    Office Fédéral de la Statistique (2017-2018). FAQ – Enquête
                    Vivre ensemble en Suisse (VeS).
                </p>
                <p>
                    White, M. & Kite, B. (2013). Psychologie des préjugés et de
                    la discrimination. De Boeck Supérieur.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        L’enquête suivante sur le racisme anti-Noir·e en Suisse
                        : Efionayi-Mäder, D., Ruedin, D., Pétrémont, M. E.,
                        Michel, N. & Jain, R. (2017). État des lieux du racisme
                        anti-Noir-e en Suisse. Swiss Forum for Migration and
                        Population Studies. Université de Neuchâtel.
                        <a
                            href="https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf"
                            target="blank"
                            >https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf</a
                        >. Le rapport comprend des définitions pointues de
                        différents types de racisme, notamment racisme culturel,
                        racisme (au) quotidien, racismes structurel,
                        institutionnel ou encore le racisme genré, dans une
                        approche intersectionnelle.
                    </li>
                    <li>
                        Wicht, L., von Lanthen, V. B., Sebeledi, D., & Eckmann,
                        M. (2017). L'incident raciste au quotidien :
                        Représentations, dilemmes et interventions des
                        travailleurs sociaux et des enseignants. Éditions ies.
                    </li>
                    <li>
                        - Stoczkowski, Wiktor (2017-02-07), Race. Anthropen.
                        <a
                            href="https://doi.org/10.17184/eac.anthropen.042"
                            target="blank"
                            >https://doi.org/10.17184/eac.anthropen.042</a
                        >
                    </li>
                </ul>

                <h4>Ressources</h4>
                <ul>
                    <li>
                        Un serious game pour lutter contre les préjugés
                        <a href="http://www.buzanglo.org/" target="blank">http://www.buzanglo.org/</a>
                    </li>
                    <li>
                        <a href="https://www.humanrights.ch/fr/pfi/droits-humains/racisme/dossier/le-racisme-cest-quoi/" target="blank">https://www.humanrights.ch/fr/pfi/droits-humains/racisme/dossier/le-racisme-cest-quoi/</a
                        >
                    </li>
                    <li>
                        L’enquête suivante sur le racisme anti-Noir·e en Suisse
                        : Efionayi-Mäder, D., Ruedin, D., Pétrémont, M. E.,
                        Michel, N., & Jain, R. (2017). Etat des lieux du racisme
                        anti-Noir-e en Suisse. Université de Neuchâtel. Vous
                        pouvez consulter l’ensemble du rapport sur
                        <a href="https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf" target="blank">https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf</a
                        >. Le rapport comprend des définitions pointues de du
                        différents types de racisme, notamment racisme culturel,
                        racisme (au) quotidien, racismes structurel,
                        institutionnel ou encore le racisme genré lien vers
                        racisme genré>, dans une approche intersectionnelle lien
                        vers RS/intersectionnalité>.
                    </li>
                </ul>

                <h2 id="xenophobie">Xénophobie</h2>
                <figure class="quote">
                    <blockquote>
                        « Du point de vue étymologique on peut définir la
                        xénophobie comme l’aversion de l’étran-ger·ère, la peur
                        de l’étranger·ère. Du point de vue des sciences
                        sociales, la xénophobie peut être caractérisée comme le
                        rejet de l’Autre du fait de sa condition, réelle ou
                        imaginaire, d’étranger·ère, de personne non-nationale. »
                    </blockquote>
                    <figcaption>(Bolzman, 2020, p, 566).</figcaption>
                </figure>
                <br />
                <h4>Référence</h4>
                <p>
                    Bolzman, C. (2020). Xénohobie. In Jean-Michel Bonvin,
                    Valérie Hugentobler, Carlo Knöpfel, Pascal Maeder,Ueli
                    Tecklenburg (dir.). Dictionnaire de politique sociale suisse
                    (pp. 566-568). Seismo.
                </p>

                <h2 id="normeBlanche">Norme blanche bourgeoise</h2>
                <figure class="quote">
                    <blockquote>
                        « Ce qui est qualifié de ‘blanc’ n’est pas l’effet d’un
                        constat chromatique ‘objectif’. Le ‘blanc’ désigne
                        plutôt le fait de jouir de ce que l’on pourrait appeler
                        une certaine ‘transparence sociale’. Cette transparence
                        sociale suppose que l’on a n’a pas à endosser de marque
                        infâmante, quelle qu’elle soit (couleur, voile, accent,
                        patronyme…), et qui donne en effet le privilège d’être
                        socialement interpellé comme une personne, plutôt que
                        comme un individu métonymique (figure de style : le tout
                        et sa partie) – constamment ramené à un prétendu groupe,
                        une entité, altérisé. »
                    </blockquote>
                    <figcaption>(Dorlin, 2009, p. 13)</figcaption>
                </figure>
                <br />
                <figure class="quote">
                    <blockquote>
                        « En effet, l’ignorance des rapports de race tend à
                        reproduire des formes d’hégémonie blanche en
                        invisibilisant ces rapports de pouvoir et en faisant
                        fonctionner la blanchité non comme une position sociale
                        particulière impliquant des avantages structurels, mais
                        comme une norme implicite soustraite à l’analyse et la
                        critique. »
                    </blockquote>
                    <figcaption>(Belkacem, 2016, p. 539).</figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Belkacem, L., Le Renard, A. & Paris, M. (2016). Race. Dans :
                    Juliette Rennes éd., Encyclopédie critique du genre: Corps,
                    sexualité, rapports sociaux (pp. 539-548). Paris: La
                    Découverte.
                </p>
                <p>
                    Dorlin, Elsa. (2009). Vers une épistémologie des
                    résistances. In Elsa Dorin (Ed.), Sexe, race, classe, pour
                    uen épistémologie de la domination (pp. 5-18). Paris : PUF.
                </p>

                <h3 id="biaisBlanc">Biais blanc masculin bourgeois</h3>
                <figure class="quote">
                    <blockquote>
                        « Le groupe adulte, blanc, de sexe mâle, catholique, de
                        classe bourgeoise, sain d’esprit et de mœurs, est donc
                        cette catégorie qui ne se définit pas comme telle et
                        fait silence sur soi-même. Elle impose aux autres
                        cependant à travers la langue sa définition comme norme.
                        »
                    </blockquote>
                    <figcaption>(Guillaumin 1992 : 294)</figcaption>
                </figure>
                <br />
                <p>
                    Pour le sociologue Damien Trawalé (2016, p. 65), les
                    hiérarchies raciales sont aujourd’hui maintenues, mais elles
                    sont souvent euphémisées. Pour analyser le racisme, il est
                    dès lors intéressant de partir de l’expérience quotidienne
                    afin comprendre les processus qui constituent le racisme,
                    entendu par l’auteur comme
                    <q
                        >« un rapport social […], la somme d’enjeux autour
                        desquels des groupes aux intérêts antagonistes sont
                        constitués. Parler de rapports sociaux de race suppose
                        alors de déceler les enjeux autour desquels les
                        différents groupes raciaux sont reproduits dans un
                        contexte donné ».</q
                    >
                    L’analyse d’interactions routinières met en évidence ce
                    processus d’altérisation, en particulier la « reproduction
                    des personnes noires comme venant d’ailleurs » (p. 66),
                    qu’illustre par exemple la question «Mais tu viens d’où?» ou
                    « Mais tu viens d’où, vraiment ? ». Donnons un autre exemple
                    tiré de sa recherche. Un jeune percussionniste, excellent
                    élève, souhaite faire du classique mais le personnel du
                    conservatoire le pousse vers le jazz, reproduisant ainsi la
                    séparation Blancs/Noirs au mépris des goûts du jeune
                    musicien :
                    <q>
                        « Moi, ce qui m’intéresse, c’est le répertoire
                        classique, je suis dingue de Berlioz, dingue de Beethov,
                        ils en ont foutu partout des percussions, ben pourquoi
                        j’irai faire du jazz, ça ne m’intéresse pas le jazz, je
                        n’aime pas le jazz »</q
                    >
                    (p. 72). Comme le conclut Trawalé (2016, pp. 73-74) pour la
                    France – mais gageons que cela concerne aussi la Suisse :
                    <q
                        >« l’appartenance raciale blanche confère dans son
                        rapport avec les appartenances non blanches une autorité
                        sociale fondée sur l’idée d’une homologie entre
                        blanchité et francité, qui permet de définir la
                        situation et de revendiquer le droit de faire faire à
                        ses subordonnés raciaux, de leur imposer ses convenances
                        »</q
                    >, si bien que la ségrégation consiste pour les personnes
                    noires (en l’occurrence) en une
                    <q
                        >« diminution de l’étendue des relations sociales et
                        implique un moindre accès à l’ensemble des services et
                        des biens ».</q
                    >
                </p>

                <h4>Références</h4>
                <p>
                    Guillaumin, Colette. (1992). Sexe, race et pratique de
                    pouvoir. L'idée de Nature. Paris : Coté-femmes.
                </p>
                <p>
                    Trawalé, Damien (2016). La reproduction sans encombre des
                    rapports de race : le cas des gays noirs. Migrations
                    Société, 163(1), 61-74
                </p>

                <h2 id="postcolonial">Postcolonial</h2>
                <p>
                    Bien que les décolonisations aient eu lieu, l’organisation
                    sociale demeure liée colonialisme et à ses conséquences
                    matérielles et idéologiques :
                </p>
                <figure class="quote">
                    <blockquote>
                        « Si le postcolonial est d’abord, au sens littéral, ce
                        qui vient ‘après le colonialisme’, la première vertu de
                        ce mot est d’exprimer un paradoxe : ‘après le
                        colonialisme’, au moment des migrations qui marquent
                        ‘l’explosion du monde impérial hors de ses frontières’
                        (Stuart Hall), il peut rester un ‘après-colonialisme’,
                        une ‘postcolonie’ (Achille Mbembe) qui survit au
                        colonialisme et le perpétue, selon des formes
                        renouvelées. Bien sûr, il n’est pas simple de déterminer
                        ce qui relève, à l’époque contemporaine, du colonial
                        dans les rapports sociaux. »
                    </blockquote>
                    <figcaption>
                        (Cohen, Dorlin, Nicolaïdis, Rahal, & Simon, 2007, p. 11)
                    </figcaption>
                </figure>
                <br />
                <h4>Références</h4>
                <p>
                    Cohen, Jim, Dorlin, Elsa, Nicolaïdis, Rahal, Malika, &
                    Simon, Patrick. (2007). Dossier. Le tournant postcolonial à
                    la française. Mouvements, 51(3), 7-12.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        d’Adesky, J. (2017). Subalternité. Anthropen.
                        <a
                            href="https://doi.org/10.17184/eac.anthropen.05"
                            target="blank"
                            >https://doi.org/10.17184/eac.anthropen.05</a
                        >
                    </li>
                    <li>
                        Pouchepadass, J. (2000). Les subaltern studies ou la
                        critique postcoloniale de la modernité. L’Homme, 156.
                        <a
                            href="https://doi.org/10.4000/lhomme.75"
                            target="blank"
                            >https://doi.org/10.4000/lhomme.75</a
                        >
                    </li>
                    <li>
                        <a
                            href="https://www.ndf.fr/politique/13-10-2018/pour-eric-fassin-et-france-culture-le-racisme-anti-blancs-est-une-fake-news/"
                            target="blank"
                            >https://www.ndf.fr/politique/13-10-2018/pour-eric-fassin-et-france-culture-le-racisme-anti-blancs-est-une-fake-news/</a
                        >
                    </li>
                </ul>

                <h3>Nord-Suds – Subaltern Studies – études décoloniales</h3>
                <p>
                    Les concepts de « Nord global » et du « Sud global » ou «
                    périphérie globale » rendent compte de rapports structurels
                    de domination issus du colonialisme :
                    <q
                        >« les rapports entre le Nord et le Sud ont des racines
                        sociohistoriques extrêmement complexes qui sont liées à
                        l’histoire entière de l’impérialisme, de Da Gama aux
                        attaques de drones. Ils impliquent différents modes de
                        colonisation, de génocide et d’exploitation, et
                        différents types d’interactions postcoloniales à travers
                        l’économie globale, les communications internationales
                        et les mass media, les migrations et la guerre »</q
                    >
                    (Martin, 2015, p. 106)
                </p>
                <p>
                    D’après la sociologue Raeywn Connell, la sociologie
                    elle-même a été produite à partir d’une distinction
                    hiérarchisée entre peuples « évolués » et « primitifs » et
                    elle a utilisé, instrumentalisé et ignoré théoriquement la
                    production des Suds (Martin, 2015). Cette hégémonie de la
                    connaissance, qui va de pair avec la limitation de
                    l’expression des subalternes, est mise en évidence par les
                    théories postcoloniales :
                    <q
                        >« les travaux critiques d’Edgardo Lander et d’Aníbal
                        Quijano (2005) se concentrent sur l’analyse de la
                        colonialité du pouvoir et du savoir, ainsi que sur la
                        critique de l’eurocentrisme, compris comme une
                        perspective binaire et dualiste de la connaissance qui
                        est venue à s’imposer mondialement de manière
                        hégémonique au fur et à mesure de l’expansion européenne
                        sur la planète »</q
                    >
                    (d'Adesky, 2017). Les subaltern studies, en tant que partie
                    des postcolonial studies, développent une critique de la
                    colonialité du pouvoir et visent à « restituer la capacité
                    des « sans-voix » marginalisés comme les paysans pauvres,
                    les femmes, les intouchables, et d'autres voix », tant dans
                    les Suds qu’au Nord : « Outre la restriction au droit à la
                    parole — donc au pouvoir d’énonciation —, ce qui rapproche
                    les subalternes du Nord et du Sud, c’est leur bas niveau de
                    revenu, qui les prive d’aisance matérielle; leur qualité de
                    vie, leur bien-être et leurs libertés qui sont donc moindres
                    que ceux des autres groupes nationaux. Ces restrictions les
                    enferment dans la spirale décrite par Amartya Sen (2010) :
                    la limitation de la liberté économique réduit les libertés
                    sociales, ce qui entraîne une nouvelle perte de liberté
                    économique. Ce cercle vicieux affaiblit les subalternes, les
                    opprime et les maintient dans un silence qui réduit leur
                    capacité d’action. » (d'Adesky, 2017).
                </p>

                <h4>Références</h4>
                <p>
                    Martin, H. (2015). Raewyn Connell, sociologue et militante
                    féministe. Des rivages du Pacifique : politiques du genre et
                    connaissance. Nouvelles Questions Féministes, 34, 102-121.
                    <a
                        href="https://doi.org/10.3917/nqf.341.0102"
                        target="blank"
                        >https://doi.org/10.3917/nqf.341.0102
                    </a>
                </p>
                <p>
                    d'Adesky, J. (2017). Subalternité. In Anthropen.org:
                    Éditions des archives contemporaines.
                    <a href="https://www.anthropen.org/voir/Subalternit%C3%A9?r" target="blank">https://www.anthropen.org/voir/Subalternit%C3%A9?r</a>
                </p>
                <p>Sen, A. (2010). L’idée de justice. Flammarion.</p>

                <h3 id="effetEveAfricain">Effet Ève Africaine</h3>
                <p>
                    Dans le prolongement des effets Matthieu et Matilda, l’«
                    effet Ève Africaine » a été théorisée par Hofmänner (2011)
                    pour rendre compte des dynamiques d'avantages et de
                    désavantages cumulatifs et des relations de pouvoir
                    discursives géopolitiquement et culturellement façonnées
                    dans le domaine scientifique entre le Nord (« un toujours
                    plus ») et le Sud (« un toujours moins »).
                </p>
                <p>
                    L’« effet Ève Africaine » traduit le fait que les modes de
                    pensée occidentale dominent encore la production et la
                    circulation des études et des connaissances scientifiques
                    considérées comme probantes, alors que celles produites au
                    Sud tendent souvent à être considérés ou classés au mieux
                    comme ne relevant pas totalement de la science ou aux
                    frontières de celle-ci, lorsqu’il s’agit des scientifiques
                    bien établis, sinon comme marginaux, hors du commun, non
                    scientifique ou relevant de travaux en "développement" dans
                    le cas des scientifiques relativement peu reconnus.
                </p>

                <h4>Référence</h4>
                <p>
                    Hofmänner, A. (2011). The African Eve Effect in Science.
                    Archaeologies: Journal of the World Archaeological Congres,
                    7, 251-288. DOI 10.1007/s11759-011-9160-1
                </p>

                <h2 id="racismeGenre">Racisme genré</h2>
                <figure class="quote">
                    <blockquote>
                        « La notion de <b>racisme genré</b> se base sur la
                        constatation qu’un autre système de rapports sociaux,
                        basé sur le genre, interagit avec le racisme tout comme
                        avec la stratification sociale, qui fondent les rapports
                        de pouvoir au sein de la société́. Ces systèmes
                        d’organisations fonctionnent selon des logiques communes
                        (de catégorisation, de hiérarchisation et
                        d’essentialisation), qui se nourrissent et s’amplifient
                        réciproquement dans leurs interactions, ce qui fait
                        l’objet de l’analyse intersectionnelle (Crenshaw 1993 ;
                        Lavanchy 2014 ; Schulz et Mullings 2006). » (2017, p.
                        10)
                    </blockquote>
                    <figcaption>
                        (Efionayi-Mäder, Ruedin, Pétrémont, Michel & Jain, 2017,
                        p. 10)
                    </figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Efionayi-Mäder, D., Ruedin, D., Pétrémont, M. E., Michel, N.
                    & Jain, R. (2017). État des lieux du racisme anti-Noir-e en
                    Suisse. Swiss Forum for Migration and Population Studies.
                    Université de Neuchâtel.
                    <a
                        href="https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf"
                        target="blank"
                        >https://doc.rero.ch/record/305687/files/Efionayi-M_der_Denise_-_Etat_des_lieux_du_racisme_anti-Noir-e_en_Suisse_20171109.pdf</a
                    >.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        Crenshaw, K. W. (1993). Beyond racism and misogyny:
                        Black feminism and 2 Live Crew. In Matsuda, Mari J. et
                        al. (éd.), Words That Wound: Critical Race Theory,
                        Assaultive Speech, And The First Amendment (pp.
                        111-132). Westview Press.
                    </li>
                    <li>
                        Lavanchy, A. (2014). How does "race" matter in
                        Switzerland? Maison d’analyse des processus sociaux
                        (MAPS).
                    </li>
                    <li>
                        Matsuda, Mari J. et al. (éd.), Words That Wound:
                        Critical Race Theory, Assaultive Speech, And The First
                        Amendment (pp. 111-132). Westview Press.
                    </li>
                    <li>
                        Schulz, A. J., & Mullings, L. (Eds.). (2006). Gender,
                        race, class, & health: Intersectional approaches. Wiley.
                    </li>
                </ul>
                <br />
                <p>Sur les normes de beauté blanche et les modèles :</p>
                <ul>
                    <li><a href="https://www.youtube.com/watch?v=NaHIb_Pf_CU" target="blank">https://www.youtube.com/watch?v=NaHIb_Pf_CU</a></li>
                    <li><a href="https://www.youtube.com/watch?v=zWoIZoVhXf4" target="blank">https://www.youtube.com/watch?v=zWoIZoVhXf4</a></li>
                </ul>

                <h1 id="rapportSocialSexe">Rapport social de sexe</h1>
                <h2 id="genre">Genre et études genre</h2>
                <figure class="quote">
                    <blockquote>
                        « La perspective de genre permet de reconsidérer les
                        rapports de pouvoir qui se nichent à toutes sortes de
                        niveaux: par exemple dans les sciences, dans la
                        médecine, dans les rapports familiaux, dans la sphère du
                        travail, dans l’espace public, à l’école, etc., pour en
                        promouvoir des formes plus égalitaires. La prise en
                        compte de cette perspective permet de repenser les
                        sociétés et constitue un apport-clé aux disciplines qui
                        se proposent de les étudier: l’histoire, la sociologie,
                        l’anthropologie, la géographie, la science politique, la
                        philosophie, la psychologie, ou encore les sciences de
                        la nature. En questionnant les fondements mêmes de ces
                        disciplines, le champ des études genre opère une
                        véritable révolution épistémologique, et permet ainsi
                        d’appréhender les rapports sociaux dans toute leur
                        complexité. Si l’approche genre est donc ouvertement
                        engagée, puisqu’elle revendique de travailler à une
                        culture plus égalitaire, proposant d’interroger les
                        espaces de liberté et ceux de domination différemment
                        répartis dans la société, elle n’en est pas moins
                        scientifiquement rigoureuse. »
                    </blockquote>
                    <figcaption>
                        (Bureau de promotion de l'égalité et de prévention des
                        violences, 2019, p. 17)
                    </figcaption>
                </figure>
                <br />

                <p>
                    Le concept de genre
                    <q
                        >« permet de montrer que dans chaque société,
                        l’organisation des relations entre les femmes et les
                        hommes relève du contexte de socialisation et de
                        l’éducation » </q
                    >(Galibert, 2018, p. 11)
                </p>

                <figure class="quote">
                    <blockquote>
                        « Communément, si le terme sexe se réfère aux
                        caractéristiques biologiques et physiologiques qui
                        différencient les hommes des femmes, le genre sert à
                        évoquer les rôles qui sont déterminés socialement, les
                        comportements, les activités et les attributs qu’une
                        société considère comme appropriés pour les hommes et
                        pour les femmes »
                    </blockquote>
                    <figcaption>(Galibert, 2018, p. 11)</figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        « Le genre est le mécanisme par lequel les notions de
                        masculin et de féminin sont produites et naturalisées,
                        mais il pourrait très bien être le dispositif par lequel
                        ces termes sont déconstruits et dénaturalisés »
                    </blockquote>
                    <figcaption>(Butler, 2016, p. 68)</figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        « Penser que le genre précède le sexe ne veut pas dire
                        que la société créé les organes sexuels mais bien
                        qu’elle les institue comme différence significative et
                        binaire, à partir du moment où ses fondations et son
                        existence sont inféodées à un ordre double et
                        asymétrique »
                    </blockquote>
                    <figcaption>(Carnino, 2005, pp. 100-101)</figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        « L'apparition de ces termes [« trans », « queer », …]
                        correspond à une réalité actuelle : la diversité et la
                        fluidité des expériences vécues par les jeunes fait
                        voler en éclat la pensée binaire, qui est d'ailleurs
                        très occidentale. Ces personnes ne se laissent plus
                        cantonner à une bicatégorisation, selon laquelle
                        masculin et féminin ou hétéro et homo seraient des
                        catégories totalement séparées et imperméables. Ainsi,
                        ces mots sont nés du besoin de se définir en adéquation
                        avec ses expériences, car il faut d'abord pouvoir ‘se
                        formuler’ à soi avant de pouvoir ‘se formuler’ aux
                        autres »
                    </blockquote>
                    <figcaption>(Romy & Summermatter, 2017)</figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Bureau de promotion de l'égalité et de prévention des
                    violences (2019). Brochure "Du sexisme ordinaire aux
                    violences sexuelles : repérer pour agir. Guide à l'intention
                    des professionnel-le-s",
                    <a href="https://www.ge.ch/document/11477/telecharger" target="blank">https://www.ge.ch/document/11477/telecharger</a>
                </p>
                <p>Butler, J. (2016). Défaire le genre. Editions Amsterdam.</p>
                <p>
                    Carnino, G. (2005). Pour en finir avec le sexisme.
                    L'Echappée.
                </p>
                <p>
                    Romy, Katy & Summermatter, Stefania (2017). Interview de
                    Caroline Dayer. Homme-femme, homo-hétéro : quand les
                    catégories volent en éclat. SWI Swissinfo.
                </p>
                <p>
                    Galibert, C. (2018). Petit manuel du genre. A l’usage de
                    toutes les générations. PUG.
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Martin, H. (2011). Les Études Genre : qu’étudient-elles donc
                    ? Revue d’information sociale REISO.
                    <a href="https://www.reiso.org/articles/themes/55-etudes-genre-qu-etudient-elles-donc" target="blank">https://www.reiso.org/articles/themes/55-etudes-genre-qu-etudient-elles-donc</a
                    >
                </p>

                <h4>Ressources</h4>
                <ul>
                    <li>
                        Jeu pédagogique « In-égalité » :
                        <a href="https://in-egalite.ch/" target="blank">https://in-egalite.ch/</a>
                    </li>
                    <li>
                        Plateforme Gender Campus :
                        <a href="https://www.gendercampus.ch/fr/" target="blank">https://www.gendercampus.ch/fr/</a>
                    </li>
                    <li>
                        Boîte à outils Genre de la HES-SO, notamment :
                        <ul>
                            <li>
                                un glossaire :
                                <a href="https://bao-genre.hes-so.ch/fr/glossaire-concepts-7824.html" target="blank"
                                    >https://bao-genre.hes-so.ch/fr/glossaire-concepts-7824.html</a
                                >
                            </li>
                            <li>
                                un moteur de recherche :
                                <a href="https://bao-genre.hes-so.ch/fr/moteur-recherche-7434.html" target="blank"
                                    >https://bao-genre.hes-so.ch/fr/moteur-recherche-7434.html</a
                                >
                            </li>
                        </ul>
                    </li>
                    <li>
                        Outil « Pour un enseignement supérieur sensible au genre
                        » développé par le Centre didactique de l’Université de
                        Fribourg :
                        <a href="https://moodle.unifr.ch/course/view.php?id=159487" target="blank">https://moodle.unifr.ch/course/view.php?id=159487</a>
                    </li>
                    <li>
                        <a href="https://www.beyounetwork.org/articles/feminisme-et-genre-une-histoire-commune" target="blank">https://www.beyounetwork.org/articles/feminisme-et-genre-une-histoire-commune</a
                        >
                    </li>
                </ul>

                <h2 id="cv">Curriculum caché</h2>
                <figure class="quote">
                    <blockquote>
                        « Le curriculum caché [désigne l’] ensemble de ces
                        éléments [valeurs, savoirs, compétences,
                        représentations, rôles] qui s’acquièrent durant la
                        scolarisation sans être prévus par le programme »
                    </blockquote>
                    <figcaption>(Mosconi, 1994 : 119)</figcaption>
                </figure>
                <br />

                <h4>Référence</h4>
                <p>
                    Mosconi, N. (1994). Femmes et Savoir. La société, l'école et
                    la division sexuelle des savoirs. L'Harmattan.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        <a href="http://www.2e-observatoire.com/downloads/livres/brochure7.pdf" target="blank">http://www.2e-observatoire.com/downloads/livres/brochure7.pdf</a
                        >
                    </li>
                    <li>
                        <a href="http://svt-egalite.fr/index.php/mecanismes/le-curriculum-cache" target="blank">http://svt-egalite.fr/index.php/mecanismes/le-curriculum-cache</a
                        >
                    </li>
                    <li>
                        Notices du jeu in-égalité :
                        <a href="https://in-egalite.ch/notice_TravailRemunere.html" target="blank">https://in-egalite.ch/notice_TravailRemunere.html</a>
                        et
                        <a href="https://in-egalite.ch/notice_TravailDomestique.html" target="blank">https://in-egalite.ch/notice_TravailDomestique.html</a
                        >
                    </li>
                    <li>
                        <a href="https://www.binge.audio/podcast/les-couilles-sur-la-table/en-musique-les-hommes-donnent-le-la" target="blank">https://www.binge.audio/podcast/les-couilles-sur-la-table/en-musique-les-hommes-donnent-le-la</a
                        >
                    </li>
                </ul>

                <h2 id="sexismeHosBienAmb">
                    Sexisme hostile et sexisme bienveillant – sexisme ambivalent
                </h2>

                <figure class="quote">
                    <blockquote>
                        <p>
                            « Les attitudes envers les femmes ne seraient pas
                            uniquement hostiles, elles pourraient aussi être
                            positives et ceci même parfois plus que celles
                            dirigées vers les hommes. Ce phénomène renvoie à
                            l’effet Women are wonderful (Eagly & Mladinic, 1989,
                            1993). Depuis toujours, les femmes ne seraient pas
                            uniquement cibles d’attitudes hostiles, elles
                            seraient aussi vénérées. Les attitudes envers les
                            femmes sembleraient donc être ambivalentes,
                            composées d’un mélange d’attitudes opposées, à la
                            fois positives et négatives. Ceci a conduit Glick et
                            Fiske (1996) à proposer la théorie du sexisme
                            ambivalent. Selon cette théorie, le sexisme
                            ambivalent correspond à la coexistence de sexisme
                            hostile et de sexisme bienveillant à l’égard des
                            femmes.
                        </p>
                        <p>
                            Plus précisément, Glick et Fiske (1996) définissent
                            le sexisme hostile comme une attitude explicitement
                            négative envers les femmes qui sont considérées
                            comme des manipulatrices aux idées féministes et
                            agressives, usant de leur séduction pour mieux
                            contrôler les hommes. Le harcèlement sexuel,
                            l’humour et les remarques sexistes ou encore les
                            violences physiques sont des exemples bien connus
                            d’attitudes sexistes hostiles envers les femmes. Le
                            sexisme hostile correspond donc au sexisme tel qu’on
                            le conçoit traditionnellement.
                        </p>
                        <p>
                            Le sexisme bienveillant est défini par ces mêmes
                            chercheurs comme une attitude subjectivement
                            positive, qui décrit les femmes comme des créatures
                            pures, qui doivent être protégées et adorées par les
                            hommes, et dont l’amour est nécessaire à ces
                            derniers pour qu’ils se sentent complets. Le sexisme
                            bienveillant est une attitude sexiste plus
                            implicite, teintée de chevalerie, qui a une
                            apparence anodine et qui semble même différencier
                            favorablement les femmes en les décrivant comme
                            chaleureuses et sociables. Néanmoins, en suggérant
                            l’idée que les femmes sont fragiles et qu’elles ont
                            besoin de la protection des hommes, le sexisme
                            bienveillant suggère également qu’elles sont
                            inférieures et moins capables qu’eux. En apparence,
                            le sexisme bienveillant est en accord avec les
                            valeurs égalitaristes imposées par nos lois et ne
                            tomberait pas dans le domaine d’application de ces
                            dernières. Il est dès lors fort probable que le
                            sexisme bienveillant reflète une réalité à laquelle
                            les femmes sont confrontées régulièrement, voir
                            quotidiennement. Ceci soutient la pertinence de
                            prendre en compte le sexisme bienveillant dans la
                            compréhension des relations entre les genres. »
                        </p>
                    </blockquote>
                    <figcaption>
                        (Sarlet & Dardenne, 2012, pp. 437-438, souligné par
                        nous)
                    </figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Sarlet, M., & Dardenne, B. (2012). Le sexisme bienveillant
                    comme processus de maintien des inégalités sociales entre
                    les genres. L’Année psychologique, 112(3), 435-463.
                    doi:10.4074/s0003503312003053
                </p>

                <h3 id="sexismeFormation">
                    Sexisme dans le cadre de la formation
                </h3>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « Pour autant qu’elle ait disparu ou qu’elle ait été
                            suspendue du fait du choix atypique, la
                            différenciation entre les sexes réapparaît au cours
                            de la formation des minoritaires : leur présence
                            suscite des réactions, tant de leurs camarades que
                            des enseignant·e·s et du monde professionnel
                            qu’elles et ils côtoient. Cette différenciation joue
                            sur un registre tantôt hostile, tantôt bienveillant
                            mais un peu paternaliste (Sarlet & Dardenne, 2012).
                            Dans les discours, peu d’interactions sont en effet
                            décrites comme obéissant à une perspective
                            égalitaire, qui transcenderait le système de genre
                            en mettant en œuvre une indifférenciation entre les
                            sexes. »
                        </p>
                    </blockquote>
                    <figcaption>(Rey & Battistini, 2016)</figcaption>
                </figure>
                <br />

                <h4>Référence</h4>
                <p>
                    Rey, S. & Battistini, M. (2016). « Chassez les stéréotypes,
                    ils reviennent au galop ! Choix atypiques de formation
                    professionnelle et différenciation des groupes de sexe »,
                    Éducation et socialisation [Online], 42 | 2016, Online since
                    23 October 2016, connection on 03 November 2020. URL:
                    <a href="http://journals.openedition.org/edso/1831" target="blank">http://journals.openedition.org/edso/1831</a> ; DOI :
                    <a href="https://doi.org/10.4000/edso.1831" target="blank">https://doi.org/10.4000/edso.1831</a>
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        Eagly, A. H., & Mladinic, A. (1989). Gender stereotypes
                        and attitudes toward women and men. Personality and
                        Social Psychology Bulletin, 15(4), 543 558.
                        <a href="https://doi.org/10.1177/0146167289154008" target="blank">https://doi.org/10.1177/0146167289154008</a>
                    </li>
                    <li>
                        Glick, P., & Fiske, S. T. (1996). The Ambivalent Sexism
                        Inventory: Differentiating hostile and benevolent
                        sexism. Journal of Personality and Social Psychology,
                        70(3), 491–512.
                        <a href="https://doi.org/10.1037/0022-3514.70.3.491" target="blank">https://doi.org/10.1037/0022-3514.70.3.491</a>
                    </li>
                    <li>
                        programme de mentorat de la HES-SO :
                        <a href="https://mentorat-hes.ch/" target="blank">https://mentorat-hes.ch/</a>
                    </li>
                    <li>
                        politique-cadre et page HES-SO dédiées à la
                        problématique du harcèlement sexuel et sexiste:
                        <a href="https://www.he-arc.ch/sites/www.he-arc.ch/files/DG/Egalite/hes-so_politique_cadre_harcelement.pdf" target="blank">https://www.he-arc.ch/sites/www.he-arc.ch/files/DG/Egalite/hes-so_politique_cadre_harcelement.pdf
                        </a>
                    </li>
                    <li>
                        brochure HES-SO « Le harcèlement sexuel : on n’en veut
                        pas » :
                        <a href="https://www.hes-so.ch/data/documents/Brochure-enligne-FR-11366.pdf" target="blank">https://www.hes-so.ch/data/documents/Brochure-enligne-FR-11366.pdf</a
                        >
                    </li>
                    <li>
                        jeu pédagogique « In-égalité » :
                        <a href="https://in-egalite.ch/" target="blank">https://in-egalite.ch/</a>, notamment la notice sur
                        le harcèlement sexuel au travail :
                        <a href="https://in-egalite.ch/notice_Harcelement.html" target="blank">https://in-egalite.ch/notice_Harcelement.html</a>
                    </li>
                    <li>
                        brochure « Non au harcèlement sexuel dans les lieux de
                        formation » :
                        <a href="http://www.formation-sans-harcelement.ch/wp-content/uploads/2017/10/Brochure-en-Fran%C3%A7ais.pdf" target="blank">http://www.formation-sans-harcelement.ch/wp-content/uploads/2017/10/Brochure-en-Fran%C3%A7ais.pdf</a
                        >
                    </li>
                    <li>
                        guide « Du sexisme ordinaire aux violences sexuelles :
                        repérer pour agir » :
                        <a href="https://www.ge.ch/document/11477/telecharger" target="blank">https://www.ge.ch/document/11477/telecharger</a>
                    </li>
                </ul>

                <h2 id="androcentrisme">Androcentrisme</h2>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « L’androcentrisme est, dans son expression la plus
                            basique, une vision du monde dans des termes
                            masculins, une reconstruction de l’univers social à
                            partir d’une perspective masculine. Spécifiquement,
                            cela signifie une construction de l’ego comme
                            masculin plutôt que féminin, et conjointement une
                            conception des femmes comme objets plutôt que comme
                            sujets, comme agies plutôt que comme actrices »
                        </p>
                    </blockquote>
                    <figcaption>(Eichler, 1988, pp. 19-20).</figcaption>
                </figure>
                <br />

                <p>L’androcentrisme se traduit par exemple par le fait que :</p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « Les femmes sont quasiment absentes des manuels,
                            que ce soit dans les textes de leçons, les documents
                            iconographiques ou les documents textes. Cette
                            sous-représentation concerne autant les personnages
                            historiques (par exemple, les auteures femmes
                            étudiées ne sont que de 5 % dans les manuels de
                            littérature), que les figures anonymes (par exemple,
                            dans les manuels de mathématiques, il y a un
                            personnage féminin pour cinq personnages masculins).
                            Dans les biographies qui figurent à la fin des
                            manuels, il n'y a pas ou très peu de femmes
                            mentionnées. »
                        </p>
                    </blockquote>
                    <figcaption>(Missoffe, 2015, p.2)</figcaption>
                </figure>
                <br />
                <h4>Référence</h4>
                <p>
                    Eichler, Margrit. (1988). Non Sexist Research Methods : A
                    Pratical Guide. Boston. Boston: Allen & Unwin.
                </p>
                <p>
                    Missoffe, Prune. (2015). Stéréotypes, représentations
                    sexuées et inégalités de genre dans les manuels scolaires.
                    La Revue des droits de l’homme, 8.
                    <a href="http://journals.openedition.org/revdh/1667" target="blank">http://journals.openedition.org/revdh/1667</a>
                    <a href="https://doi.org/10.4000/revdh.1667" target="blank">https://doi.org/10.4000/revdh.1667</a>
                </p>

                <h4>Pour aller plus loin</h4>
                <h5>
                    Des pistes pour « [d]éjouer l’androcentrisme » en sciences
                    se trouvent dans cet ouvrage paru en 2016 :
                </h5>
                <p>
                    Devreux, Anne-Marie (2016). Les sciences et le genre.
                    Presses universitaires de Rennes. ISBN 978-2-7535-5000-1
                    <a href="http://www.pur-editions.fr/detail.php?idOuv=4181" target="blank">http://www.pur-editions.fr/detail.php?idOuv=4181</a>
                </p>
                <p><a href="http://www.pur-editions.fr/detail.php?idOuv=4181" target="blank">http://www.pur-editions.fr/detail.php?idOuv=4181</a></p>

                <h5>
                    Pour une réflexion sur l’enseignement et la posture
                    enseignante à partir d’un matériel androcentrique
                </h5>
                <p>
                    Brunet, M.-H. & Demers, S. (2018). Déconstruire le manuel
                    d’histoire pour (re)construire des savoirs plus justes :
                    récit de pratique en formation initiale et continue des
                    enseignantes et des enseignants. Recherches féministes, 31
                    (1), 123–140. <a href="https://doi.org/10.7202/1050657ar" target="blank">https://doi.org/10.7202/1050657ar</a>
                </p>

                <h5>
                    Pour une discussion sur les inégalités de genre dans les
                    carrières
                </h5>
                <p>
                    Farinaz Fassa, Martin Benninghoff et Sabine Kradolfer, «
                    Universités : les politiques d’égalité entre femmes et
                    hommes à l’heure de l’excellence », SociologieS [En ligne],
                    Dossiers, Universités : les politiques d’égalité entre
                    femmes et hommes à l’heure de l’excellence, mis en ligne le
                    27 octobre 2019, consulté le 11 février 2020. URL :
                    <a href="http://journals.openedition.org/sociologies/11773" target="blank">http://journals.openedition.org/sociologies/11773</a>
                </p>

                <h4>Liens moins académique</h4>
                <ul>
                    <li>https://www.youtube.com/watch?v=Ltu5PTSaUv0</li>
                    <li>
                        https://www.ted.com/talksmichael_kimmel_why_gender_equality_is_good_for_everyone_men_included?language=fr
                    </li>
                </ul>

                <h3>Ressources</h3>
                <ul>
                    <li><a href="https://mentorat-hes.ch/" target="blank">https://mentorat-hes.ch/</a></li>
                    <li>
                        <a href="https://www.arte.tv/fr/videos/094558-000-A/emma-clit-traque-le-sexisme-bienveillant/" target="blank">https://www.arte.tv/fr/videos/094558-000-A/emma-clit-traque-le-sexisme-bienveillant/</a
                        >
                    </li>
                    <li>
                        <a href="https://www.letemps.ch/culture/breves-histoires-culottees" target="blank">https://www.letemps.ch/culture/breves-histoires-culottees</a
                        >
                    </li>
                    <li><a href="https://svin.ch/wordpress/fr/" target="blank">https://svin.ch/wordpress/fr/</a></li>
                    <li><a href="https://musicdirectory.ch/fr/" target="blank">https://musicdirectory.ch/fr/ </a></li>
                    <li>
                        <a href="https://www.epfl.ch/about/equality/fr/conferences/femmes-de-science/" target="blank">https://www.epfl.ch/about/equality/fr/conferences/femmes-de-science/</a
                        >
                    </li>
                    <li><a href="https://lescreatives.ch/" target="blank">https://lescreatives.ch/</a></li>
                </ul>

                <h2 id="mathilda">Effet Mathilda</h2>
                <p>
                    Mis en évidence par Margaret W. Rossiter (1993), l’« effet
                    Matilda » témoigne de nombreux cas, historiques et
                    contemporains, de travaux, découvertes scientifiques de
                    femmes qui ont été systématiquement ignorés, déniés, privés
                    de crédit, oubliés ou non reconnus par leurs pairs, voire
                    même dans certains attribués à leurs pairs masculins avec
                    qui elles ont mené leurs recherches. Ce phénomène dénote
                    donc d’une appréciation a priori défavorable aux carrières
                    scientifiques des femmes, évincées de l’histoire officielle
                    de leurs disciplines, comparativement à leurs collègues
                    hommes (Fassa, Kradolfer & Paroz, 2012).
                </p>
                <p>
                    Ainsi, dans la communauté scientifique, les carrières des
                    femmes dans les sciences se présenterait comme des histoires
                    invisibles ou « invisibilisées », avec une moindre
                    reconnaissance comparativement à leurs pairs hommes d’une
                    même discipline, en termes notamment d’obtention de
                    financements, de publications, de participations à des
                    conférences, à des sociétés savantes, des prix, etc. Ces
                    femmes feraient face à une sorte d’inconvenance du lien
                    femme/science, avec comme conséquence une invalidation de la
                    contribution scientifique des femmes, ayant souvent été dans
                    des rôles de « collaboratrices », des « petites mains », au
                    service de leurs comparses masculins, masquant ainsi leur
                    réelle contribution. Tout se passerait comme si l’excellence
                    scientifique n’existe pas aussi au féminin alors l’absence
                    des femmes dans les récits classiques du progrès des
                    sciences relève plutôt d’un processus d’occultation misogyne
                    que d’une réalité (Fauvel, Coffin, & Trochu, 2019), comme en
                    témoigne l’expression « pères fondateurs ».
                </p>

                <h4>Références</h4>
                <p>
                    Fassa, F., Kradolfer, S., & Paroz, S. (2012). Enquête au
                    royaume de Matilda. La relève à l’Université de Lausanne.
                    Rapport de recherche. Lives Working paper.
                    <a href="http://dx.doi.org/10.12682/lives.2296-1658.2012.11" target="blank">http://dx.doi.org/10.12682/lives.2296-1658.2012.11</a>
                </p>
                <p>
                    Fauvel, A., Coffin, J.-C., & Trochu, T. (2019). Les
                    carrières de femmes dans les sciences humaines et sociales
                    (XIXe-XXe siècles) : une histoire invisible ? Revue
                    d’histoire des sciences humaines [En ligne], 35 | mis en
                    ligne le 15 décembre 2019, consulté le 01 janvier 2021. URL:
                    <a href="http://journals.openedition.org/rhsh/3971" target="blank">http://journals.openedition.org/rhsh/3971</a>; DOI:
                    <a href="https://doi.org/10.4000/rhsh.3971" target="blank">https://doi.org/10.4000/rhsh.3971</a>
                </p>
                <p>
                    Hausman, B. L. (2000). Do Boys Have to Be Boys? Gender,
                    Narrativity, and the John/Joan Case. NWSA Journal, 12,
                    114-138.
                </p>
                <p>
                    Rossiter, M. W. (1993). The Matthew/ Matilda Effect in
                    Science. Social Studies of Science, 23, 325-341.
                </p>

                <h4>Pour aller plus loin</h4>
                <ul>
                    <li>
                        Sources vidéo
                        <ul>
                            <li>
                                https://www.youtube.com/watch?v=BDFPSpwP83s&t=11s
                            </li>
                            <li>https://www.youtube.com/watch?v=KFuuZ4f4P00</li>
                        </ul>
                    </li>
                </ul>

                <h2 id="sexisme">Sexisme</h2>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « Le sexisme peut être défini comme toutes les
                            attitudes, croyances ou comportements
                            discriminatoires basés sur le sexe et sur des
                            conceptions stéréotypées des sexes; il s’agit d’un
                            ensemble d’idées et d’attitudes qui hiérarchise les
                            sexes de façon arbitraire postulant la supériorité
                            de la catégorie des hommes sur celle des femmes.
                        </p>
                        <p>
                            Malgré les avancées en matière d’égalité entre
                            femmes et hommes, et malgré les engagements pris par
                            la Suisse pour combattre les discriminations à
                            l’égard des femmes, le sexisme demeure néanmoins une
                            réalité prégnante. Une des causes principales de sa
                            ténacité est son caractère invisible. Par
                            conséquent, ses manifestations doivent tout d’abord
                            être repérées pour pouvoir être surmontées. »
                        </p>
                    </blockquote>
                    <figcaption>
                        (Bureau de promotion de l'égalité et de prévention des
                        violences, 2019, p. 9)
                    </figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        <p>
                            « Le sexisme est un essentialisme : comme le
                            racisme, d’ethnie ou de classe, il vise à imputer
                            des différences sociales historiquement instituées à
                            une nature biologique fonctionnant comme une essence
                            d’où se déduisent implicablement tous les actes de
                            l’existence. Et entre toutes les formes
                            d’essentialisme, il est sans doute le plus difficile
                            à déraciner »
                        </p>
                    </blockquote>
                    <figcaption>(Bourdieu, 1990, p. 12)</figcaption>
                </figure>
                <br />
                <figure class="quote">
                    <blockquote>
                        <p>
                            « [L]’expérience corporelle féminine est le plus
                            souvent celle de la retenue et de l’inhibition […C]e
                            rapport au corps résulte de l’éducation dispensée
                            aux filles dès le plus jeune âge : non seulement
                            celles-ci sont couramment incitées à percevoir leur
                            corps comme plus fragile et moins capable que celui
                            des garçons, mais elles ont en outre plus rarement
                            qu’eux l’occasion de développer la totalité de leurs
                            capacités physiques, notamment parce que les
                            activités qui permettent de le faire sont souvent
                            perçues comme trop dangereuses pour elles. Or, […]
                            cette éducation n’inhibe pas seulement le corps des
                            femmes, mais affecte aussi, plus largement, leur
                            confiance en soi »
                        </p>
                    </blockquote>
                    <figcaption>(Court, 2016, p. 325)</figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Bourdieu, Pierre. (1990). La domination masculine. Actes de
                    la recherche en sciences sociales, 84, 2-31.
                </p>
                <p>
                    Bureau de promotion de l'égalité et de prévention des
                    violences (2019). Brochure "Du sexisme ordinaire aux
                    violences sexuelles : repérer pour agir. Guide à l'intention
                    des professionnel-le-s",
                    <a href="https://www.ge.ch/document/11477/telecharger" target="blank">https://www.ge.ch/document/11477/telecharger</a>
                </p>
                <p>
                    Court, Martine. (2016). Incorporation. In Juliette Rennes
                    (Ed.), Encyclopédie critique du genre: corps, sexualité,
                    rapports sociaux (pp. 321-330). La Découverte.
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Vidéo LIVES « La banale histoire de Louise » :
                    <a href="https://vimeo.com/289653157?ref=fb-share&1" target="blank">https://vimeo.com/289653157?ref=fb-share&1</a>
                </p>

                <h2 id="heterosexisme">Hétérosexisme et hétéronormativité</h2>
                <h3>Hétérosexisme et hétéronormativité</h3>
                <p>
                    La fiche Définitions sur la diversité sexuelle et de genre
                    (<a href="https://chairedspg.uqam.ca/upload/files/fiches-realises/D%C3%A9finitions_diversit%C3%A9_sexuelle_et_de_genre.pdf" target="blank">https://chairedspg.uqam.ca/upload/files/fiches-realises/D%C3%A9finitions_diversit%C3%A9_sexuelle_et_de_genre.pdf</a>)
                    développée par la Chaire de recherche sur la diversité
                    sexuelle des genres de l’UQAM en collaboration avec la
                    Chaire de recherche sur l’homophobie de l’UQAM définit les
                    concepts d’hétérosexisme et d’hétéronormativité :
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « Le concept d’hétérosexisme réfère à l’affirmation
                            de l’hétérosexualité comme norme sociale ou comme
                            étant supérieure aux autres orientations sexuelles.
                            Il découle de l’hétérosexisme des pratiques
                            culturelles, sociales, légales et institutionnelles
                            qui nient, ignorent, dénigrent ou stigmatisent
                            toutes formes non hétérosexuelles de comportements,
                            d’identités ou de relations. L’hétéronormativité
                            renvoie à l’affirmation d’idéologies normatives en
                            matière de sexes, de genres, d’orientations
                            sexuelles et de rôles sociaux. L’hétéronormativité
                            présente ces dimensions dans un système qui postule
                            la binarité des sexes (masculin/féminin), des genres
                            (homme/femme), des rôles sociaux (p. ex. père/mère)
                            et des orientations sexuelles
                            (hétérosexuelle/homosexuelle), et à l’alignement de
                            ces dimensions (sexe
                            féminin/femme/mère/hétérosexuelle ; sexe
                            masculin/homme/père/hétérosexuel).
                            L’hétéronormativité met donc en place un système
                            dominant dans lequel les personnes qui ne respectent
                            pas ces normes (comme les personnes non
                            hétérosexuelles, trans, ou non conformes aux
                            stéréotypes de leur genre) sont considérées comme
                            étant inférieures. Si l’hétéronormativité dicte les
                            conduites et les normes à suivre en matière de
                            sexes, de genres et d’orientations sexuelles,
                            l’hétérosexisme en assure le maintien, par
                            l’exclusion sociale, la discrimination ou
                            l’invisibilisation des individus dérogeant à ces
                            normes. »
                        </p>
                        <p>
                            Le concept d’hétéronormativité « renvoie à
                            l’affirmation d’idéologies normatives en matière de
                            sexes, de genres, d’orientations sexuelles et de
                            rôles sociaux. L’hétéronormativité présente ces
                            dimensions dans un système qui postule la binarité
                            des sexes (masculin/féminin), des genres
                            (homme/femme), des rôles sociaux (p. ex. père/mère)
                            et des orientations sexuelles
                            (hétérosexuelle/homosexuelle), et à l’alignement de
                            ces dimensions (sexe
                            féminin/femme/mère/hétérosexuelle ; sexe
                            masculin/homme/père/hétérosexuel).
                            L’hétéronormativité met donc en place un système
                            dominant dans lequel les personnes qui ne respectent
                            pas ces normes (comme les personnes non
                            hétérosexuelles, trans, ou non conformes aux
                            stéréotypes de leur genre) sont considérées comme
                            étant inférieures. »
                        </p>
                    </blockquote>
                </figure>
                <br />

                <h4>Ressources</h4>
                <p><a href="https://diversite-au-travail.ch/" target="blank">https://diversite-au-travail.ch/ </a></p>
                <p>
                    <a href="https://diversite-au-travail.ch/wp-content/uploads/2019/11/Fede_guide_BP_2019_V2_ok_web.pdf" target="blank">https://diversite-au-travail.ch/wp-content/uploads/2019/11/Fede_guide_BP_2019_V2_ok_web.pdf
                    </a>
                </p>
                <p>
                    <a href="https://www.beyounetwork.org/articles/notre-tedx-talk-linfluence-des-stereotypes-de-genre-sur-notre-quotidien" target="blank">https://www.beyounetwork.org/articles/notre-tedx-talk-linfluence-des-stereotypes-de-genre-sur-notre-quotidien</a
                    >
                </p>

                <h3 id="cisnormativite">
                    Cisnormativité, cissexisme, cisgenrisme
                </h3>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « La cisnormativité est la présomption qu’être
                            cisgenre est la norme valide et que le cadre de la
                            binarité des sexes doit servir de référence pour la
                            détermination de ce qui est normal (valide) ou non.
                            Le cissexisme est la présomption que toutes les
                            personnes sont cisgenres ; le cissexisme contribue à
                            occulter les autres identités de genre et à affirmer
                            la seule binarité homme-femme comme étant valide. »
                        </p>
                    </blockquote>
                    <figcaption>(Dubuc, 2017 : 11)</figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        <p>
                            « Le cisgenrisme est un système d’oppression qui
                            touche les personnes trans, parfois nommé
                            transphobie. Il se manifeste sur le plan juridique,
                            politique, économique, social, médical et normatif.
                            Dans ce dernier cas, il s’agit de
                            cisgenrenormativité. Je préfère la notion de
                            cisgenrisme à celle de transphobie, car elle
                            s’éloigne des origines pathologiques et
                            individuelles de la « phobie »
                        </p>
                    </blockquote>
                    <figcaption>(Baril, 2015 : 2)</figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Baril, A. (2015). Sexe et genre sous le bistouri
                    (analytiques) : interprétations féministes des
                    transidentités. Recherches féministes, 28, 2, 121-141.
                </p>
                <p>
                    Dubuc, D. (2017). Les mots de la diversité liée au sexe, au
                    genre et à l’orientation sexuelle, fneeq-csn.
                </p>

                <h4>Pour aller plus loin</h4>
                <h5>Pistes et ressources pour des pratiques respectueuses</h5>
                <ul>
                    <li>
                        Sansfaçon, A. P., Baril, A., Lee, E. O., Vigneau, M. É.,
                        Manning, K. E., & Faddoul, M. (2020). « On vous tolère,
                        mais on ne vous accepte pas » : luttes pour la
                        reconnaissance des jeunes trans dans un contexte
                        cisnormatif ». Canadian Social Work Review, 37(1),
                        43A-61.
                    </li>
                    <li>
                        <a href="https://www.tgns.ch/fr/" target="blank">https://www.tgns.ch/fr/</a> et
                        <a href="https://www.tgns.ch/fr/medias/guide-linguistique/" target="blank">https://www.tgns.ch/fr/medias/guide-linguistique/</a>
                    </li>
                    <li><a href="https://agnodice.ch/" target="blank">https://agnodice.ch/ </a></li>
                    <li>
                        <a href="http://svt-egalite.fr/index.php/reflexions-et-outils/transidentite/comment-prendre-en-compte-les-eleves-trans" target="blank">http://svt-egalite.fr/index.php/reflexions-et-outils/transidentite/comment-prendre-en-compte-les-eleves-trans</a
                        >
                    </li>
                    <li>
                        <a href="https://www.cliquezjustice.ca/vos-droits/f-m-x-le-genre-et-les-pieces-d-identite-au-canada" target="blank">https://www.cliquezjustice.ca/vos-droits/f-m-x-le-genre-et-les-pieces-d-identite-au-canada</a
                        >
                    </li>
                    <li>
                        <a href="https://www.btb.termiumplus.gc.ca/publications/diversite-diversity-fra.html" target="blank">https://www.btb.termiumplus.gc.ca/publications/diversite-diversity-fra.html</a
                        >
                    </li>
                    <li>
                        Mémoire de maîtrise de Maxime Faddoul :
                        <a href="https://archipel.uqam.ca/13648/1/M16372.pdf" target="blank">https://archipel.uqam.ca/13648/1/M16372.pdf</a>
                    </li>
                    <li>
                        <a href="https://assigneegarcon.tumblr.com/post/107706465595/un-court-guide-dinclusion-des-%C3%A9l%C3%A8ves-trans" target="blank"></a>
                        https://assigneegarcon.tumblr.com/post/107706465595/un-court-guide-dinclusion-des-%C3%A9l%C3%A8ves-trans
                    </li>
                </ul>

                <h3 id="pratiqueEnseignement">
                    Pratiques respectueuses dans l’enseignement
                </h3>
                <p>
                    Le Guide d’accompagnement pour l’inclusion des personnes
                    trans dans l’enseignement supérieur en Fédération
                    Wallonie-Bruxelles
                    (<a href="http://www.egalite.cfwb.be/index.php?id=18743" target="blank">http://www.egalite.cfwb.be/index.php?id=18743</a>)
                    propose des pistes pour un enseignement supérieur
                    respectueux et inclusif. Il attire notamment l’attention sur
                    le droit au respect de la vie privée, ce qui implique de ne
                    pas « outer » une personne identifiée comme homosexuelle,
                    bisexuelle ou trans*. Selon ce guide :
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            Le « outing » est le fait de révéler
                            l’homosexualité/la bisexualité ou la transidentité
                            d’une personne sans son consentement, voire contre
                            sa volonté. Il est différent du « coming-out » qui
                            repose sur une démarche volontaire. Pour les
                            personnes trans, les difficultés liées à la
                            modification des documents d’identité ou autres
                            (tableaux de notes affichés aux valves, cartes
                            d’étudiant-e-s, etc.) les exposent au quotidien à
                            une situation de « outing permanent » (p. 11).
                        </p>
                        <p>
                            Ces situations de « outing » constituent une
                            atteinte à la vie privée et au droit à
                            l’auto-détermination pouvant avoir des conséquences
                            importantes pour la personne qui se retrouve ainsi
                            exposée et fragilisée. Ainsi, un tiers des personnes
                            transgenres évitent d’exprimer leur identité de
                            genre par leur apparence ou leur façon de s’habiller
                            par peur d’être menacées, harcelées ou agressées. Il
                            est donc important que la vie privée des personnes
                            trans soit correctement protégée au sein des
                            établissements d’enseignement et que chaque
                            étudiant-e reste libre d’exprimer - ou non - sa
                            transidentité. (p. 13)
                        </p>
                    </blockquote>
                    <figcaption></figcaption>
                </figure>
                <br />

                <p>
                    Un autre point soulevé pour des pratiques respectueuse est
                    le respect de l’autodétermination des personnes trans*.
                    C’est ce que souligne Maxime Faddoul dans son mémoire de
                    Maîtrise.
                </p>

                <figure class="quote">
                    <blockquote>
                        <p>
                            Le mégenrage (le non-respect des pronoms et accords
                            choisis) est une autre forme de non-respect des
                            personnes trans. Aussi, nous avons vu plus haut que
                            la validation de l'identité de genre des personnes
                            trans est une pratique renforçant
                            l'autodétermination de celles-ci. Cependant, cela ne
                            signifie pas que les intervenant·e·s ont des
                            pratiques qui soutiennent constamment ce processus
                            d'autodétermination. En effet, durant les entrevues,
                            des intervenant·e·s ont mégenré (utilisation du
                            mauvais nom et pronom) à maintes reprises les
                            personnes trans qu'iels rencontrent dans le cadre de
                            leur pratique, notamment lorsqu'iels faisaient
                            référence aux personnes trans avant leur transition
                            de genre (utilisation des mauvais pronoms lorsque
                            l'on parle de la personne trans avant le dévoilement
                            de son statut trans ).
                        </p>
                    </blockquote>
                    <figcaption>(Faddoul, 2019, p. 111)</figcaption>
                </figure>
                <br />

                <h4>Référence</h4>
                <p>
                    Faddoul, Maxime (2019). Articulation des questions trans en
                    travail social : pratiques et points de vue des
                    intervenant.e.s sociaux [Mémoire de Maîtrise en travail
                    social]. Université du Québec à Montréal, Canada.
                </p>
                <h1 id="rapportSocialClasse">Rapport social de classe</h1>
                <p>
                    Le concept de classe sociale fait l’objet de plusieurs
                    théorisations et débats en sciences sociales et politiques.
                    Beverly Skeggs (2015) soulève par exemple une série de
                    difficultés pour définir la classe : « Quand on parle de
                    classe, parle-t-on par exemple de structure de classe,
                    d'identité de classe, de conscience de classe, d'action de
                    classe ? » (p. 45). Nous nous limitons à retracer quelques
                    éléments qui permettent d’appréhender celle-ci comme un
                    rapport social.
                </p>
                <p>
                    Skeggs (2015) propose le résumé suivant de l’approche de la
                    classe sociale proposée par Bourdieu :
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            Bourdieu propose un modèle des classes fondé sur la
                            circulation des capitaux à travers l'espace social.
                            […] Les classes sociales, dans le modèle de
                            Bourdieu, n'existent pas en soi : la classe n'est ni
                            une essence, ni un ensemble de signifiés fluctuants,
                            mais une définition, aux effets sociaux bien réels,
                            imposée arbitrairement par les mobilisations
                            collectives et les luttes de classement. Bourdieu
                            identifie quatre types différents de capitaux : le
                            capital économique, culturel, social et symbolique.
                        </p>

                        <ul>
                            <li>
                                1. Le capital économique, qui inclut le revenu,
                                le patrimoine, les héritages et les actions.
                            </li>
                            <li>
                                2. Le capital culturel […] n'existe qu'en
                                relation au système des autres capitaux et de
                                leur distribution différentielle.
                            </li>
                            <li>
                                3. Le capital social, généré par les relations
                                sociales, correspond à des ressources, liées à
                                la possession d'un réseau de relations
                                d'interconnaissance mobilisables, plus ou moins
                                institutionnalisées.
                            </li>
                            <li>
                                4. Le capital symbolique correspond aux
                                différentes formes de capital en tant qu'elles
                                sont perçues et reconnues comme légitimes. La
                                légitimation est un mécanisme clé dans la
                                conversion en pouvoir. Les ressources
                                culturelles doivent être légitimées pour exercer
                                du pouvoir.
                            </li>
                        </ul>
                    </blockquote>
                    <figcaption>(Skeggs, 2015, p. 49-50)</figcaption>
                </figure>
                <br />

                <p>
                    Dans les sciences sociales et politiques, mais aussi dans
                    des discours ordinaires, on retrouve parfois l’idée que la
                    question des classes sociales n’est pas (ou plus) si
                    importante. Skeggs (2015) soulève à ce sujet que la classe
                    <q
                        >« n'apparaît pas problématique, en effet, à ceux et
                        celles qui ont le privilège de pouvoir l'ignorer »</q
                    >. Elle développe :
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            On doit penser également au rapport entre savoir et
                            responsabilité : ignorer les classes ou les rendre
                            invisibles, c'est (sur la base du privilège)
                            abdiquer la responsabilité des effets qu'elles
                            produisent. Penser que les classes n'ont pas
                            d'importance est la prérogative de ceux qui ne sont
                            pas touchés par les privations et les exclusions
                            qu'elles impliquent.
                        </p>
                    </blockquote>
                    <figcaption>(Skeggs, 2015, p. 47)</figcaption>
                </figure>
                <br />
                <p>
                    S’il est difficile de prendre consciences de ses propres
                    privilèges (tout comme pour les rapports sociaux de race, de
                    sexe, d’âge ou le capacitisme), on peut garder à l’esprit
                    que les ignorer ne fait que renforcer les inégalités,
                    notamment dans l’enseignement, et qu’au contraire, des
                    pédagogies dites « critiques » favorisent la lutte contre
                    différentes oppressions.
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            Indépendamment des espaces de formation, les
                            contenus comme les manières d’enseigner sont
                            susceptibles tant de transformer que de perpétuer
                            les rapports sociaux de genre, de classe ou de race,
                            pour ne nommer que ceux-là.
                        </p>
                    </blockquote>
                    <figcaption>(Pagé, Solar & Lampron, 2018, p.1)</figcaption>
                </figure>
                <br />

                <h4>Référence</h4>
                <p>
                    Pagé, G. Solar, C. & Lampron, E.-M. (2018). Les pédagogies
                    féministes et les pédagogies des féminismes : une mise en
                    perspective. Recherches féministes, 31(1), 1-21.
                </p>
                <p>
                    Skeggs, Beverley. (2015). Des femmes respectables. Paris:
                    Agone.
                </p>

                <h4>Pour aller plus loin</h4>
                <h5>Quelques textes-clés</h5>
                <ul>
                    <li>
                        Pierre Bourdieu & Jean-Claude Passeron, 1964, Les
                        Héritiers. Les étudiants et la culture, Les Éditions de
                        Minuit.
                    </li>
                    <li>
                        Pierre Bourdieu & Jean-Claude Passeron, 1970, La
                        Reproduction. Éléments d’une théorie du système
                        d’enseignement, Les Éditions de Minuit.
                    </li>
                    <li>
                        Pierre Bourdieu, 1979, La Distinction. Critique sociale
                        du jugement, Les Éditions de Minuit.
                    </li>
                    <li>
                        Delay, C. (2011). Les classes populaires à l'école. La
                        rencontre ambivalente entre deux cultures à légitimité
                        inégale, Presses universitaires de Rennes, coll. « Le
                        sens social »,
                        <a href="https://www.hetsl.ch/fileadmin/_migrated/news_import/BC_les_classes_populaires_a_l_ecole_1_.pdf" target="blank">https://www.hetsl.ch/fileadmin/_migrated/news_import/BC_les_classes_populaires_a_l_ecole_1_.pdf</a
                        >
                    </li>
                    <li>
                        Kuehni, M. (2017). Des expériences de pauvreté
                        laborieuse dans un contexte d’activation sociale: une
                        perspective de genre. Recherches féministes, 30(2),
                        81-100.
                    </li>
                    <li>
                        Siblot, Y., Cartier, M. Coutant, I., Masclet, O., &
                        Renahy, N. (2015). Sociologie des classes populaires
                        contemporaines, Armand Colin, coll. « U Sociologie ».
                        <a href="https://journals.openedition.org/lectures/16825" target="blank">https://journals.openedition.org/lectures/16825</a>
                    </li>
                </ul>
                <h5>Pédagogies critiques</h5>
                <ul>
                    <li>
                        Bayer, V., Rollin, Z., Martin, H., & Modak, M. (2018).
                        L’intervention féministe : un continuum entre pratiques
                        et connaissances. Nouvelles questions féministes, 37(2),
                        6-12.
                    </li>
                    <li>
                        Freire. P. (1980). La pédagogie des opprimés. F.
                        Maspero.
                    </li>
                    <li>
                        Freire. P. (2004). Pédagogie de l’autonomie : savoirs
                        nécessaires à la pratique éducative, érès.
                    </li>
                </ul>

                <h4>Ressources</h4>
                <ul>
                    <li>
                        Projet
                        <a
                            href="https://hesav.ch/recherche/axes-de-recherche/axe-pratiques-professionnelles-et-interprofessionnelles/co-construire-une-pedagogie-de-l-egalite-dans-la-hes-so/"
                            >« Co-construire une pédagogie de l'égalité au sein
                            de la HES-SO »</a
                        >
                    </li>
                    <li>
                        Émission « Dans la tête… d’un pauvre »,<a href="https://www.rts.ch/play/tv/magazine/video/dans-la-tete-----dun-pauvre?urn=urn:rts:video:9392105" target="blank">https://www.rts.ch/play/tv/magazine/video/dans-la-tete-----dun-pauvre?urn=urn:rts:video:9392105</a
                        >
                    </li>
                    <li>
                        Des classes sociales dans la société française
                        contemporaine ?
                        <a href="https://www.youtube.com/watch?v=WXTVEPj65jw" target="blank">https://www.youtube.com/watch?v=WXTVEPj65jw</a>
                    </li>
                </ul>

                <h2 id="matthieu">Effet Matthieu</h2>
                <p>
                    Dans le domaine académique, où l’effet Matthieu a été mis en
                    évidence pour la première fois par Robert K. Merton (1968),
                    il est démontré que la compétition académique entre
                    chercheurs est dynamiquement biaisée par un processus
                    d'auto-renforcement positif pour les uns et
                    d'auto-renforcement négatif cumulatif pour les autres : 1)
                    plus on obtient des financements, plus on mène des
                    recherches, plus on publie, plus on est cité, et plus notre
                    expertise est reconnue dans le domaine (« un toujours plus
                    ») ; 2) moins on obtient des financements, moins on réalise
                    des recherches, moins on publie, moins on est cité, et moins
                    notre expertise est reconnue dans le domaine, « un toujours
                    moins » (Bol, de Vaan, & van de Rijt, 2018 ; Carayol, 2006 ;
                    Fusulier, 2016).
                </p>
                <p>
                    Le terme d’« effet Matthieu » renvoie à un texte souvent
                    cité de l'Évangile de Mathieu, chapitre 13, vers. 12 : « Car
                    à celui qui a, l'on donnera, et il sera dans l’abondance ;
                    mais à celui qui n'a pas, on enlèvera même ce qu'il a ».
                </p>
                <p>
                    Illustré dans plusieurs domaines, l’effet Matthieu désigne
                    de manière générale un biais dynamique d’inégalité
                    auto-renforcée caractérisée par un accès progressé et une
                    possession de ressources plus prononcée (économique, de
                    pouvoir politique, de prestige, de connaissance ou à toute
                    autre ressource rare ou valorisée) des personnes appartenant
                    à des groupes sociaux déjà favorisés (riches, hommes,
                    classes sociales favorisées, chercheurs les plus productifs)
                    comparativement à leurs pairs appartenant à des groupes
                    sociaux au départ défavorisés (pauvres, femmes, classes
                    sociales défavorisées, chercheurs les moins productifs (« un
                    toujours plus » contre « un toujours moins », Rigney, 2013).
                </p>
                <p>
                    En d’autres termes, l’effet Matthieu désigne le processus
                    selon lequel les riches deviendraient plus riches, les
                    pauvres plus pauvres, les groupes au départ favorisés
                    deviendraient davantage plus favorisés tandis que les
                    groupes défavorisés deviendraient encore défavorisés.
                </p>

                <h4>Références</h4>
                <p>
                    Bol, T., de Vaan, M., & van de Rijt, A. (2018). The Matthew
                    effect in science funding. PNAS, 115 (19), 4887-4890.
                </p>
                <p>
                    Carayol, N. (2006). Les propriétés incitatives de l’effet
                    Saint Matthieu dans la compétition académique. Revue
                    économique, 57, 1033-1051.
                </p>
                <p>
                    Fusulier, B. (2016). Faire une carrière scientifique
                    aujourd’hui. Quelques clés de lecture et critiques. In E.
                    Zaccaï, B. Timmermans, M. Hudon, B. Clerbaux, B. Leclercq &
                    H. Bersini. L'évaluation de la recherche en question(s) (p.
                    102-110). Académie Royale de Belgique.
                    <a href="http://hdl.handle.net/2078.1/176597" target="blank">http://hdl.handle.net/2078.1/176597</a>
                </p>
                <p>
                    Merton, R.K. (1968). The Matthew effect in science: The
                    reward and communication systems of science are considered.
                    Science, 159, 56–63.
                </p>
                <p>
                    Rigney, D. (2013). The Matthew effect: how advantage begets
                    further advantage. Columbia, UK: Columbia University Press.
                </p>

                <h2>
                    La construction de la méritocratie scolaire et de la «
                    noblesse scientifique ».
                </h2>
                <p>
                    Dans la plupart des sociétés dites démocratiques, la
                    méritocratie est parmi les principes ou les normes qui
                    fondent la justice sociale parmi les individus et les
                    groupes sociaux. Dans le domaine scolaire,
                    <q
                        >« premier espace au sein duquel, par le jeu de
                        classements permanents, des hiérarchies sont produites,
                        qui sont censées être justes, puisqu’on ne saurait
                        croire au mérite si les épreuves qui l’étalonnent
                        n’apparaissent pas impartiales. Les valeurs du mérite,
                        de récompense proportionnelle à l’effort fourni y sont
                        centrales. L’enfant apprend donc à considérer qu’il
                        reçoit des notes conformes à son mérite »</q
                    >, (Duru-Bellat & Tenret, 2009, p. 234), les inégalités
                    scolaires
                    <q
                        >« y sont jugées acceptables (voire justes) si et
                        seulement si elles sont perçues comme découlant des
                        qualités individuelles (talents, efforts) récompensées
                        au terme d’une compétition ouverte et non de propriétés
                        « héritées » (notamment l’origine sociale, le sexe) »</q
                    >
                    (Duru-Bellat & Tenret, 2009, p. 229-230). En d’autres
                    termes, la méritocratie scolaire, tout comme la « noblesse
                    scientifique » sont à l’image du sexisme, du racisme, de
                    l’âgisme et autres rapports sociaux, des formes d’idéologies
                    dominantes, des systèmes de « mythes légitimateurs »
                    psychologiquement et socialement fonctionnels, des
                    représentations du monde socialement construites tendant
                    d’une part à soutenir l’ordre social existant, et d’autre
                    part à amener les individus à croire en un monde juste et à
                    leur donner l’illusion que chacun·e y a ce qu’il mérite et
                    mérite ce qu’il/elle a (Bourdieu & Passeron, 1970 ; Lerner,
                    1980 ; ). A termes,
                    <q
                        >« dans un univers où tous fournissent d’intenses
                        efforts (ou ont le sentiment de les fournir) et sont
                        donc méritants, les [élèves/étudiant·es..] sont conduits
                        à admettre que ce qui distingue ceux qui réussissent des
                        autres tient bien à la possession de qualités d’une
                        autre nature. Les qualités valorisées ne se rencontrent
                        pourtant pas au hasard : l’intuition, la curiosité ou
                        encore la rapidité sont davantage reconnues chez les
                        garçons et dans les établissements les plus élitistes.
                        Les jugements des enseignants, les commentaires des
                        bulletins ou des copies, véritables formes de
                        désignation et d’élection, agissent dès lors comme des
                        instances de production de vocations ou de modération
                        des aspirations. Le portrait du scientifique érigé en
                        génie plutôt qu’en travailleur, recouvrant l’opposition
                        classique entre le brillant et le besogneux, conduit
                        donc à transformer des inégalités sociales en
                        différences de nature. »</q
                    >
                    (Blanchard, Orange & Pierrel, 2017, p. 85).
                </p>
                <h4>Références</h4>
                <p>
                    Blanchard, M., Orange, S. & Pierrel, A. (2017). La noblesse
                    scientifique : Jugements scolaires et naturalisation des
                    aspirations en classes préparatoires aux grandes écoles.
                    Actes de la recherche en sciences sociales, 5(5), 68-85.
                    <a href="https://doi.org/10.3917/arss.220.0068" target="blank">https://doi.org/10.3917/arss.220.0068</a>
                </p>
                <p>
                    Bourdieu, P. & Passeron, J.-C. (1970). La reproduction.
                    Éléments pour une théorie du système d’enseignement.
                    Éditions de Minuit.
                </p>
                <p>
                    Duru-Bellat, M. & Tenret, E. (2009). L’emprise de la
                    méritocratie scolaire : quelle légitimité ? Revue française
                    de sociologie, 50, 229-258.
                </p>
                <p>
                    Lerner, M. J. (1980). The belief in a just world: a
                    fundamental delusion. Plenum Press.
                </p>

                <h1 id="capacitisme">Capacitisme</h1>
                <h2>Définitions</h2>
                <p>
                    Le rapport de domination impliqué dans la distinction entre
                    individus capables et incapables relève du capacitisme,
                    terme par lequel on peut traduire « ableism » (Baril &
                    Trevenen, 2018): sur le modèle du racisme, du sexisme ou du
                    spécisme, le capacitisme traduit
                    <q
                        >« les différenciations et les hiérarchisations sociales
                        entre individus constitués comme capables ou non en
                        fonction des normes de chaque époque » </q
                    >(Piecek, Tabin, Perrin & Probst, 2017)
                </p>
                <p>
                    Monika Piecek, Jean-Pierre Tabin, Céline Perrin and Isabelle
                    Probst, « La normalité en société capacitiste », SociologieS
                    [Online], Theory and research, Online since 13 November
                    2017, connection on 12 May 2021. URL :
                    <a href="http://journals.openedition.org/sociologies/6412" target="blank">http://journals.openedition.org/sociologies/6412</a>
                </p>
                <p>
                    Le capacitisme peut être défini comme
                    <q
                        >« une structure de différenciation et de
                        hiérarchisation sociale fondée sur la normalisation de
                        certaines formes et fonctionnalités corporelles et sur
                        l’exclusion des corps non conformes et des personnes qui
                        les habitent. Le capacitisme ‘fait système’ au sens où
                        il infuse et structure tous les aspects de la vie en
                        société (subjectivités et identités, relations sociales
                        et arrangements sociaux, institutions, représentations
                        et environnements), et ce, dans toutes les sphères de la
                        vie sociale. »</q
                    >
                    (Masson, 2013, p.115)
                </p>

                <h4>Références</h4>
                <p>
                    Baril, A., & Trevenen, K. (2018). Des transformations «
                    extrêmes »: Le cas de l’acquisition volontaire de handicpas
                    pour (re)penser les solidarités entre les mouvements
                    sociaux. Recherches féministes, 27(1), 49-67.
                </p>
                <p>
                    Masson, D. (2013). Femmes et handicap. Recherches Féministes, 26(1), 111-129.
                </p>
                <p>
                    Piecek, M. Jean-Pierre Tabin, J.-P., Perrin, C. & Probst, I.
                    (2017). La normalité en société capacitiste. SociologieS
                    [Online], Theory and research, Online since 13 November
                    2017, connection on 12 May 2021.
                    <a href="http://journals.openedition.org/sociologies/6412" target="blank">http://journals.openedition.org/sociologies/6412</a>
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Pont, E. (2018). La construction du parcours éducatif et
                    professionnel des femmes paraplégiques : s’émanciper par la
                    potentielle contribution des pédagogies féministes
                    d’inspiration freirienne et poststructuraliste. Recherches
                    féministes, 31 (1), 83–104.
                    <a href="https://doi.org/10.7202/1050655ar" target="blank">https://doi.org/10.7202/1050655ar</a>
                </p>
                <p>
                    <a href="https://www.reiso.org/articles/themes/handicaps/375-un-lexique-de-la-normalite" target="blank">https://www.reiso.org/articles/themes/handicaps/375-un-lexique-de-la-normalite</a
                    >
                </p>

                <h2 id="mesuresCompensatoires">Mesures compensatoires</h2>
                <p>
                    Malgré le travail de déconstruction des catégories «
                    handicap », « validité / invalidité » et plus généralement
                    les normes capacitistes (ou validistes), la société reste
                    structurée selon des normes, et les modèles biomédical,
                    social et capacitiste co-existent. C’est pourquoi, des
                    mesures de compensation sont prévues par la loi afin
                    d’atténuer certains désavantages.
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Pour en savoir plus :
                    <a href="https://www.formationprof.ch/dyn/24079.aspx" target="blank">https://www.formationprof.ch/dyn/24079.aspx</a>
                </p>
                <p>
                    Larochelle-Audet, J., Borri-Anadon, C. & Potvin, M. (2016).
                    La formation interculturelle et inclusive du personnel
                    enseignant : conceptualisation et opérationnalisation de
                    compétences professionnelles. Éducation et francophonie,
                    44(2), 172–195.<a href="https://doi.org/10.7202/1039027ar" target="blank">https://doi.org/10.7202/1039027ar</a>
                </p>
                <p>
                    Potvin, M. (214). Diversité ethnique et éducation inclusive
                    : fondements et perspectives. Éducation et sociétés, 33,
                    185-202.
                </p>

                <h1 id="agisme">Âgisme</h1>
                <p>
                    La littérature scientifique montre que l’âge sert de
                    principe d’organisation sociale, c’est-à-dire qu’il
                    structure différents groupes d’âge dans des relations
                    d’identité et de pouvoir. Les classifications par âge sont
                    hiérarchisées : dans les sociétés occidentales, elles
                    valorisent la jeunesse en l’opposant à la vieillesse,
                    envisagée comme une succession de pertes.
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « [M]obiliser l’âge comme catégorie d’analyse des
                            rapports sociaux conduit à explorer l’organisation
                            d’asymétries fondées tant sur un écart d’âge civil
                            que sur un écart d’âge social. Alors que l’âge civil
                            ou « calendaire » d’une personne désigne la durée,
                            mesurée en années, depuis sa date de naissance
                            inscrite dans l’état civil, son âge social renvoie à
                            la façon dont ses activités, son statut social et
                            son apparence corporelle (éthos, hexis, façon de
                            s’habiller, signes visibles de sénescence…) la
                            positionnent, aux yeux des autres et à ses propres
                            yeux, dans une « tranche d’âge » dont la perception
                            peut varier selon les situations. »
                        </p>
                    </blockquote>
                    <figcaption>(Rennes, 2016, p. 42-43).</figcaption>
                </figure>
                <br />
                <figure class="quote">
                    <blockquote>
                        <p>
                            « L’âgisme repose sur une vision stéréotypée et
                            dépréciative des personnes âgées, perçues comme
                            déclinantes et dépendantes, malades et peu
                            attirantes, conservatrices et incapables de
                            s’adapter aux changements (sociaux ou
                            technologiques). Inutiles, ces personnes
                            représentent un fardeau pour les plus jeunes ;
                            déclinantes, elles n’ont plus aucune passion ou
                            projet ; dépassées et incompétentes, elles doivent
                            se tenir en retrait. Cette vision se traduit par des
                            comportements condescendants ou d’évitement, de la
                            discrimination et de l’exclusion de certains espaces
                            ou sphères d’activités, comme le travail, les
                            discussions politiques ou la sexualité. Elle a des
                            effets délétères sur les personnes âgées (image de
                            soi négative, retrait et isolement), ainsi que des
                            coûts économiques et sociaux (Puijalon & Trincaz,
                            2000; Nelson, 2002; Billette, Marier & Séguin,
                            2018). L’âgisme repose sur une homogénéisation (les
                            personnes âgées sont toutes semblables), une
                            dépréciation (le vieillissement est toujours
                            négatif, il est décrépitude et dépendance) et une
                            essentialisation (cette décrépitude et cette
                            dépendance sont naturelles et inévitables. »
                        </p>
                    </blockquote>
                    <figcaption>(Gagnon, 2019).</figcaption>
                </figure>
                <br />

                <figure class="quote">
                    <blockquote>
                        <p>
                            « Faire preuve d’âgisme, c’est donc, en d’autres
                            termes, véhiculer tout type de stéréotypes négatifs
                            sur la personne âgée pouvant dans certains cas aller
                            jusqu’au fait d’avoir des réactions hostiles à leur
                            égard, ou à l’égard de la vieillesse. »
                        </p>
                    </blockquote>
                    <figcaption>
                        (Adam, Joubert, & Missotten, 2013, p. 4-5)
                    </figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Adam, Stéphane, Joubert, Sven, & Missotten, Pierre. (2013).
                    L'âgisme et le jeunisme : conséquences trop méconnues par
                    les cliniciens et chercheurs ! [Ageism and the cult of
                    youth: Consequences that are overlooked too much by
                    clinicians and researchers!]. Revue de neuropsychologie,
                    5(1), 4-8. doi:10.3917/rne.051.0004
                </p>
                <p>
                    Gagnon, E. (2019-03-12), Âgisme. Anthropen.
                    <a href="http://doi.org/10.17184/eac.anthropen.089" target="blank">http://doi.org/10.17184/eac.anthropen.089</a>
                </p>
                <p>
                    Rennes J. (2016). Âge. In Juliette Rennes (éd.),
                    Encyclopédie critique du genre. Corps, sexualité, rapports
                    sociaux (pp. 42-53). La Découverte, « Hors collection
                    Sciences Humaines ».
                </p>

                <h4>Pour aller plus loin</h4>
                <p>
                    Boudjemadi, V. (2009). L’âgisme : étude de la nature, des
                    théories explicatives et des mesures directes et indirectes
                    d’un phénomène psychosocial.
                    <a href="https://hal.univ-lorraine.fr/tel-01752703/document" target="blank">https://hal.univ-lorraine.fr/tel-01752703/document</a>
                </p>
                <p>
                    Calasanti, T.M., & Slevin, K.F. (2006). Gender, social
                    inequalities, and aging. AltaMira Press.
                </p>
                <p>
                    Calasanti, T.M, F. Slevin, K.F. & King, N. (2006). Ageism
                    and Feminism: From "Et Cetera" to Center, NWSA Journal 18,
                    13-30.
                </p>
                <p>
                    Maggiori, C. (2020). Âgisme. In Bonvin, J. M., Hugentobler,
                    V., Knöpfel, C., Maeder, P., & Tecklenburg, U (Eds).
                    Dictionnaire de politique sociale suisse. (p. 36-38).
                    Seismo.
                    <a href="https://www.seismoverlag.ch/site/assets/files/16358/oa_9782883517295.pdf" target="blank">https://www.seismoverlag.ch/site/assets/files/16358/oa_9782883517295.pdf</a>
                </p>
                <p>
                    Marquet, M., Missotten, P., & Adam, S. (2016). Âgisme et
                    surestimation des difficultés cognitives des personnes âgées
                    : une revue de la question. Gériatrie et Psychologie
                    Neuropsychiatrie du Vieillissement. 14 (2), 177-86
                </p>
                <p>
                    Rennes, J. (2019). Déplier la catégorie d’âge: Âge civil,
                    étape de la vie et vieillissement corporel dans les
                    préjudices liés à l’« âge ». Revue française de sociologie,
                    vol. 60(2), 257-284. https://doi.org/10.3917/rfs.602.0257
                </p>
                <p>
                    Tabin, J.-P., Perriard, A., (2014). Le rapport social d’âge
                    dans les politiques sociales », dans revue ¿ Interrogations
                    ?, N°19. Implication et réflexivité – II. Tenir une double
                    posture, décembre 2014 [en ligne],
                    <a href="http://www.revue-interrogations.org/Le-rapport-social-d-age-dans-les" target="blank">http://www.revue-interrogations.org/Le-rapport-social-d-age-dans-les</a
                    >
                </p>

                <h1 id="rapportPouvoirEnseignant">
                    Rapports de pouvoir entre personnes enseignantes et
                    personnes aux études
                </h1>
                <p>
                    Le monde de l’éducation est largement tributaire d’une
                    transmission mécanique et surplombante du savoir qui
                    reproduit les rapports de domination. Une telle démarche
                    pédagogique « descendante » et les logiques de performance
                    qu’elle véhicule ordinairement entre en contradiction tant
                    avec la critique de ces mêmes rapports de pouvoir qu’avec
                    une visée émancipatrice de la connaissance. Dès les années
                    1970, des pédagogies alternatives ont été proposées, en
                    héritage des dispositifs de conscientisation du pédagogue
                    Paulo Freire (2013) et des groupes de parole du Mouvement de
                    libération des femmes. Actives et critiques, ces pédagogies
                    s portent l’attention sur les rapports entre étudiant·es et
                    enseignant·es, y compris en prenant en compte les
                    vulnérabilités et les émotions (Rollin & Bayer, 2018) et
                    combinent expérience, théorie, action et prise en
                    considération du ressenti (Fisher, 2018).
                </p>
                <p>
                    En effet, dans la salle de cours / d’école comme dans toute
                    autre contexte, des rapports sociaux déterminent les rôles
                    et les attentes des un·es et des autres : l’enseignant·e,
                    face aux étudiant·es, règne « en maître » : il ou elle est
                    écouté·e, décide des tours de paroles, organise les
                    évaluations, etc. Toutefois, les enseignant·es issu·es des
                    groupes minorisés bénéficient a priori d’un moindre crédit
                    de légitimité que les hommes. De plus, si une enseignante
                    femme parle de sexisme ou un·e enseignant·e Noir·e de
                    racisme, elle et il risquent d’être considéré·es comme
                    partiaux et subjectifs, au contraire de l’enseignant blanc
                    considéré comme dispensant un savoir neutre et objectif
                    (voir notice construction de la science, biais blanc
                    bourgeois, et vidéo
                    <a
                        href="https://www.ted.com/talks/michael_kimmel_why_gender_equality_is_good_for_everyone_men_included?language=fr#t-25431"
                        target="blank"
                    >
                        Michael Kimmel : Pourquoi l’égalité est bonne pour tous
                        – y compris les hommes).</a
                    >
                    Enfin, entre les étudiant·es, les rapports sociaux se
                    (re)jouent également (voir notice rapports sociaux).
                </p>
                <figure class="quote">
                    <blockquote>
                        <p>
                            « Comment alors penser des modalités pédagogiques
                            pour former à la déconstruction des rapports sociaux
                            de sexe, ce à quoi appelle l’intervention féministe
                            ? Pour définir ce que serait une pédagogie
                            féministe, plusieurs auteures, s’inspirant en
                            particulier du dispositif de conscientisation hérité
                            du pédagogue Paulo Freire (2013 [1969]) et des
                            groupes de parole du Mouvement de libération des
                            femmes, insistent dès les années 1970 sur le fait
                            qu’une telle démarche pédagogique ne peut se limiter
                            à des savoirs transmis de manière descendante. Elle
                            interroge la relation pédagogique elle-même, la
                            finalité des enseignements et les rapports entre
                            savoirs et expériences. Or les études de genre, au
                            même titre que d’autres champs de la connaissance,
                            restent tributaires d’une transmission mécanique et
                            surplombante du savoir et des logiques de
                            performance promues dans le monde de l’éducation.
                            Dans ces conditions, les étudiant·e·s ne peuvent
                            mesurer le sens et la portée des connaissances qui
                            leur seraient utiles pour construire une expertise
                            professionnelle et une réflexivité. »
                        </p>
                    </blockquote>
                    <figcaption>
                        (Bayer, Rollin, Martin, & Modak, 2018, p. 9).
                    </figcaption>
                </figure>
                <br />

                <h4>Références</h4>
                <p>
                    Bayer, V., Rollin, Z., Martin, H. & Modak, M. (2018).
                    L’intervention féministe : un continuum entre pratiques et
                    connaissances. Nouvelles Questions Féministes, vol. 37(2),
                    6-12. https://doi.org/10.3917/nqf.372.0006, p.9
                </p>
                <p>
                    Fisher, Berenice. (2018). Qu’est-ce que la pédagogie
                    féministe ? [What is feminist pedagogy?]. Nouvelles
                    Questions Féministes, 37(2), 64-75. doi:10.3917/nqf.372.0064
                </p>
                <p>
                    Freire, Paulo. (2013). Pédagogie de l'autonomie. Toulouse:
                    ERES.
                </p>
                <p>
                    Rollin, Zoé, & Bayer, Véronique. (2018). Former les
                    travailleuses sociales et les travailleurs sociaux : une
                    intervention féministe. [Training social workers: A feminist
                    intervention]. Nouvelles Questions Féministes, 37(2), 76-89.
                    doi:10.3917/nqf.372.0076
                </p>

                <h1 id="impressum">Impressum</h1>
                <h2>Responsabilité du projet</h2>
                <p>
                    N’Dri Paul Konan, professeur, Haute école de travail social
                    et de la santé Lausanne (HETSL), HES-SO
                </p>
                <p>
                    Hélène Martin, professeure, Haute école de travail social et
                    de la santé Lausanne (HETSL), HES-SO
                </p>
                <p>
                    Maria Sokhn, professeure, HEG - Haute école de gestion Arc,
                    Neuchâtel, HES-SO
                </p>

                <h2>Conception et rédaction des notices</h2>
                <p>
                    N’Dri Paul Konan, professeur, Haute école de travail social
                    et de la santé Lausanne (HETSL), HES-SO
                </p>
                <p>
                    Hélène Martin, professeure, Haute école de travail social et
                    de la santé Lausanne (HETSL), HES-SO
                </p>
                <p>
                    Rebecca Bendjama, chargée de recherche, Haute école de
                    travail social et de la santé Lausanne (HETSL), HES-SO
                </p>

                <h2>Illustration, graphisme et développement digital</h2>
                <p>Manon Roland, graphiste et illustratrice</p>
                <p>
                    Camille Pellaton, assistante HEG - Haute école de gestion
                    Arc, Neuchâtel, HES-SO
                </p>
                <p>
                    Charline Unternährer, assistante HEG - Haute école de
                    gestion Arc, Neuchâtel, HES-SO
                </p>
                <p>
                    Jonas Venzin, assistant HEG - Haute école de gestion Arc,
                    Neuchâtel, HES-SO
                </p>

                <h2>Remerciements</h2>
                <p>
                    Nous avons à coeur de remercier les nombreuses personnes, à
                    l’interne de la HETSL comme à l’externe, qui se sont
                    impliquées à différentes étapes de la construction de
                    l’auto-évaluation, et par lesquelles notre travail a
                    bénéficié d’expertises. Au sein de la HETSL, le personnel
                    d’enseignement et de recherche, en particulier le corps
                    intermédiaire, y ont grandement contribué.
                </p>

                <h2>Financement</h2>
                <p>
                    HES-SO et Swissuniversities, Plan d’action Égalité &
                    Diversité 2017-2020
                </p>
            </v-col>
            <v-col cols="6" md="4">
                <v-list rounded style="position: absolute">
                    <v-subheader>Table des matières</v-subheader>
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            :href="'../Notice#' + item.code"
                        >
                            <v-list-item-content>
                                <v-list-item-title
                                    v-text="item.title"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Notice",
    data() {
        return {
            items: [
                {
                    title: "Indications préalables sur les notices",
                    code: "indication",
                },
                { title: "Rapports sociaux", code: "rapportSociaux" },
                { title: "Rapport social de race", code: "rapportSocialRace" },
                { title: "Rapport social de sexe", code: "rapportSocialSexe" },
                {
                    title: "Rapport social de classe",
                    code: "rapportSocialClasse",
                },
                { title: "Capacitisme", code: "capacitisme" },
                { title: "Âgisme", code: "agisme" },
                {
                    title: "Rapports de pouvoir",
                    code: "rapportPouvoirEnseignant",
                },
                { title: "Impressum", code: "impressum" },
            ],
        };
    },
};
</script>

<style scoped>
body {
    margin: 1em;
    font: 1.2rem/1.4 Georgia, serif;
}
.quote {
    margin: 0;
    background: #eee;
    padding: 1em;
    border-radius: 1em;
}
.quote figcaption,
.quote blockquote {
    margin: 1em;
}
q {
    background: #eee;
    font-style: italic;
}
</style>