<template>
    <div class="bg-blue pt-4 text-center">
        <v-card class="pa-4 ma-auto" width="500px">
            <form @submit.prevent="login()">
                <h2>Login</h2>
                <v-text-field
                        type="email"
                        placeholder="Email address..."
                        v-model="email"
                />
                <v-text-field
                        type="password"
                        placeholder="password..."
                        v-model="password"
                />
                <v-btn color="primary" type="submit">
                    Login
                </v-btn>
            </form>
        </v-card>
    </div>
</template>

<script>
    import firebase from "firebase"

    export default {
        name: "Login",
        data() {
            return {
                email: '',
                password: '',
            };
        },
        methods: {
            login() {
                firebase
                    .auth()
                    .signInWithEmailAndPassword(this.email, this.password)
                    .then(() => {
                        this.$store.commit('setUser', {email : this.email});
                        this.$router.push('/results');
                    })
                    .catch(error => {
                        this.$router.push('/login');
                        alert(error.message);
                    });
            },
        }
    }
</script>

<style scoped>

</style>