<template>
  <v-app id="app">
    <v-row>
      <v-bottom-navigation
          class="nav elevation-0 d-flex align-center"
          :background-color="bgBlue">
        <router-link to="/">
          <v-btn align="center">
            <span class="font-weight-bold">Accueil</span>
          </v-btn>
        </router-link>
        <router-link to="/about">
          <v-btn>
            <span class="font-weight-bold">Le projet</span>
          </v-btn>
        </router-link>
        <router-link to="/dans-la-vie-de">
          <v-btn id="btn-dlpd">
            <span class="font-weight-bold">Dans la vie de...</span>
          </v-btn>
        </router-link>
        <router-link to="/Quiz">
          <v-btn>
            <span class="font-weight-bold">GDI</span>
          </v-btn>
        </router-link>
        <router-link to="/Notice">
          <v-btn>
            <span class="font-weight-bold">Notices</span>
          </v-btn>
        </router-link>
      </v-bottom-navigation>
    </v-row>
    <router-view/>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    bgBlue: '#31D1FF'
  })
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Amaranth:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');
#app {
  font-family: 'Roboto', 'Amaranth', sans-serif;
  color: #2c3e50;
}
  #btn-dlpd {
    max-width: 100%;
  }
</style>
