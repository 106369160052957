<template>
  <div>
    <v-container class="quiz dv-container">
      <div id="surveyContainer">
        <survey :survey="survey"></survey>
        <div id="surveyResult"></div>
      </div>
      <Thumbnail :dialog='this.getSeenState()' :vignette='this.getThumbnail()' :message='this.getBulleMsg()' />
    </v-container>

    <div id="pions" class="pt-2">
        <transition-group name="slither" tag="p" class="d-inline-flex flex-wrap" stlye="width:90%;">
          <div class="pions-container" style="display: inline-block; padding-right: 7px; z-index: 100; padding-top: 5px" v-for="index in pionCounter" :key="index">
            <img src="img/pion.svg" alt="pions" width="45px" :class="index%2 == 0 ? 'pt-4' : ''"/>
          </div>
        </transition-group>
        <span id="porte" class="d-none d-lg-inline" style="width: 10%">
          <img src="img/porte_partie_gauche.png" alt="porte_gauche" width="50px" style="position: absolute; right: 0; z-index: 50">
          <img src="img/porte_partie_droite.png" alt="porte_droite" width="50px" style="position: absolute; right: 0; z-index: 150">
        </span>
    </div>
    <audio id="door-sound" src="files/door_sound.mp3" preload="auto"></audio>
  </div>

</template>
<script>
import * as SurveyVue from "survey-vue";
import firebase from 'firebase';
import Thumbnail from "@/components/Thumbnail";
import store from "@/store/index";

var Survey = SurveyVue.Survey;

SurveyVue.Serializer.addProperty("question", {name: "score:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "score:number"});

SurveyVue.Serializer.addProperty("question", {name: "maxScore:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "maxScore:number"});

SurveyVue.Serializer.addProperty("question", {name: "cancelling"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "cancelling"});

SurveyVue.Serializer.addProperty("question", {name: "scoreRacisme:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "scoreRacisme:number"});

SurveyVue.Serializer.addProperty("question", {name: "scoreSexisme:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "scoreSexisme:number"});

SurveyVue.Serializer.addProperty("question", {name: "scoreCapacitisme:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "scoreCapacitisme:number"});

SurveyVue.Serializer.addProperty("question", {name: "scoreClasse:number"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "scoreClasse:number"});

SurveyVue.Serializer.addProperty("question", {name: "bulle:texte"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "bulle:texte"});

SurveyVue.Serializer.addProperty("itemvalue", {name: "categorie:array"});
SurveyVue.Serializer.addProperty("itemvalue", {name: "categorie:array"});


export default {
  components: {
    Survey,
    Thumbnail,
  },
  name: "Quiz.vue",
  props: {
    dialog: Boolean,
  },
   data() {
    var json = {
      "locale": "fr",
      "title": "Divergent Quiz",
      "pages": [
        {
          "name": "startPage",
          "elements": [
            {
              "type": "html",
              "name": "titleStartPage",
              "html": {
                "fr": "<div style='max-width: 80%;'><h1><strong>Un outil </strong></h1>\n" +
                        "<p>Vous enseignez au sein de la Haute &Eacute;cole Sp&eacute;cialis&eacute;e de Suisse Occidentale</p>\n" +
                        "<p>&hellip; ou vous avez envie de r&eacute;fl&eacute;chir &agrave; des pratiques enseignantes &eacute;galitaires et inclusives&nbsp;! &nbsp;</p>\n" +
                        "<p>Le &laquo;&nbsp;Gender and Diversity Index (GDI)&nbsp;&raquo; est un outil d&eacute;velopp&eacute; pour vous par une &eacute;quipe de recherche de la HES-SO. &nbsp;Il vous permet d&rsquo;auto-&eacute;valuer vos pratiques d&rsquo;enseignement &agrave; l&rsquo;aune d&rsquo;une perspective Genre et Diversit&eacute;, avec un accent mis sur les rapports de race et de sexe.</p>\n" +
                        "<h3><strong>Une approche novatrice</strong></h3>\n" +
                        "<p>Combinant recherches scientifiques et innovation technologique, cet outil est une approche ludique &ndash; malgr&eacute; le s&eacute;rieux des sujets concern&eacute;s &ndash; qui permet aux enseignant&middot;es de d&eacute;couvrir de mani&egrave;re virtuelle aussi bien les dynamiques des rapports sociaux de race, de sexe, de classe que les rapports sociaux capacitistes et &acirc;gistes que peuvent renfermer nos pratiques enseignantes, sans que nous nous en rendions n&eacute;cessairement compte.</p>\n" +
                        "<h3><strong>Un processus de participation</strong></h3>\n" +
                        "<p>Au travers de 13 situations r&eacute;parties en trois temps (Avant l&rsquo;enseignement, Pendant l&rsquo;enseignement, Apr&egrave;s l&rsquo;enseignement), vous serez amen&eacute;&middot;es &agrave; vous mettre &laquo;&nbsp;dans la peau&nbsp;&raquo; d&rsquo;un&middot;e enseignant&middot;e HES dans une salle de classe virtuelle avec des &eacute;tudiant&middot;es.</p>\n" +
                        "<p>Apr&egrave;s avoir lu le descriptif de la situation, il s&rsquo;agira de choisir de mani&egrave;re aussi sinc&egrave;re que possible la ou les modalit&eacute;&middot;s de r&eacute;ponses qui correspond&middot;ent le mieux &agrave; votre opinion ou &agrave; ce que vous auriez fait parmi les diff&eacute;rentes possibilit&eacute;s qui vous sont propos&eacute;es.</p>\n" +
                        "<p>Vous avez la possibilité de choisir plusieurs modalités de réponses durant tout le jeu.</p>\n" +
                        "<h3><strong>Un objectif du jeu</strong></h3>\n" +
                        "<p>Le but du jeu est de conserver un maximum de participation des &eacute;tudiant&middot;es &agrave; votre enseignement.&nbsp;</p>\n" +
                        "<p>Le questionnaire int&egrave;gre&nbsp;:</p>\n" +
                        "<ul>\n" +
                        "<li>une classe virtuelle qui se maintient ou se vide en fonction de vos r&eacute;ponses,</li>\n" +
                        "<li>des r&eacute;actions de la part d&rsquo;&eacute;tudiant&middot;es de la classe lorsque vos r&eacute;ponses leur paraissent discriminantes ou probl&eacute;matiques du point de vue des rapports sociaux,</li>\n" +
                        "<li>des notices qui vous permettront d&rsquo;approfondir vos connaissances et d&rsquo;am&eacute;liorer votre &laquo;&nbsp;score&nbsp;&raquo; &hellip; et votre vigilance en mati&egrave;re de rapports sociaux dans les pratiques d&rsquo;enseignement.</li>\n" +
                        "</ul>\n" +
                        "<p>Les notices peuvent &ecirc;tre consult&eacute;es et utilis&eacute;es aussi peu ou autant que vous le souhaitez</p>\n" +
                        "<h3><strong>Un temps du jeu</strong></h3>\n" +
                        "<p>Environ <strong>20 minutes</strong>.</p>\n" +
                        "<p><strong>A vous de jouer ! </strong></p>"+
                        "<p>Conformément à la Loi fédérale sur la protection des données (LPD), aucune donnée personnelle n’est enregistrée dans ce jeu, et toutes les réponses sont anonymes</p></div>"
              }
            }
          ]
        },
        {
          "name": "page1",
          "elements": [
            {
              "type": "html",
              "name": "title1",
              "html": {
                "fr": "<h1 class='text-title'>Avant l'enseignement</h1><p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>La rentrée se rapproche. Vous préparez votre support de cours en vue de la séance d’introduction. Vous recherchez une image libre de droit pour illustrer les interactions en classe. Lors de votre recherche, vous repérez une série d’images, dont celle-ci.</p><img class='quiz-img' src='img/illu_Q1.png' alt='illu_Q1'/><p class='text-title text-right'>Vous imaginez-vous l'utiliser pour votre support de cours ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question1",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "1",
                  "text": "Je choisirais volontiers cette image pour mon support.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle":"A qui s’adresse cette image ? Je vois dans ce choix des éléments <a href='../Notice#intersectionnalite' target='_blank' rel='noopener noreferrer'> de racisme </a> et <a href='../Notice#androcentrisme' target='_blank' rel='noopener noreferrer'>de sexisme.</a>",
                  "categorie":['intersectionnalite','androcentrisme']
                },
                {
                  "value": "2",
                  "text": "Je renoncerais à utiliser cette image.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle":""
                }
              ]
            },
            {
              "type": "checkbox",
              "name": "question1_sub",
              "visibleIf": "{question1} = ['2']",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "Ce qui m’a sauté aux yeux, c’est le nombre élevé d’hommes sur l’image.",
                  "score": 1,
                  "cancelling": ["B", "C"],
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle":"A qui s’adresse cette image ? L’image est androcentrique, mais présente aussi des <a href='../Notice#racisme' target='_blank' rel='noopener noreferrer'>éléments de racisme.</a>",
                  "categorie":['racisme']
                },
                {
                  "value": "B",
                  "text": "Ce qui m’a sauté aux yeux, c’est que toutes les personnes sont blanches sur l’image.",
                  "score": 1,
                  "cancelling": ["A", "C"],
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle":"A qui s’adresse cette image ? L’image présente un biais blanc, mais aussi du <a href='../Notice#sexisme' target='_blank' rel='noopener noreferrer'>sexisme.</a>",
                  "categorie":['sexisme']
                },
                {
                  "value": "C",
                  "text": "Un autre aspect lié aux rapports sociaux me dérangeait.",
                  "score": 1,
                  "cancelling": ["A", "B"],
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": "Cette image ne s’adresse de loin pas à toutes les personnes aux études. Elle comporte aussi des éléments de <a href='../Notice#racisme' target='_blank' rel='noopener noreferrer'>racisme</a> et de <a href='../Notice#sexisme' target='_blank' rel='noopener noreferrer'>sexisme</a>.",
                  "categorie":['racisme','sexisme']
                },
                {
                  "value": "D",
                  "text": "Un autre aspect plutôt esthétique ou technique me dérangeait.",
                  "score": 2,
                  "cancelling": ["A", "B", "C"],
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": "A qui s’adresse cette image ? J’y vois des éléments de <a href='../Notice#sexisme' target='_blank' rel='noopener noreferrer'>racisme</a> et de <a href='../Notice#sexisme' target='_blank' rel='noopener noreferrer'>sexisme</a>, auxquels votre pratique enseignante ne semble pas accorder de vigilance particulière",
                  "categorie":['racisme','sexisme']
                }
              ]
            }
          ]
        },
        {
          "name": "page2",
          "elements": [
            {
              "type": "html",
              "name": "title2",
              "html": {
                "fr": "<p class='text-title'>Lorsque je prépare mon cours, je privilégie l’intégration et la mise en valeur :</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question2",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "De mes propres travaux et de ceux de mes collègues.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cool ! Mais ce choix peut être problématique du point de vue des <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['rapportSociaux']
                },
                {
                  "value": "B",
                  "text": "Des travaux contemporains reconnus comme les plus pertinents dans la discipline enseignée.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cool ! Mais ce choix peut être problématique du point de vue <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>des rapports sociaux</a> (effet Matthieu, effet Mathilda et effet Eve africaine).',
                  "categorie":['rapportSociaux']
                },
                {
                  "value": "C",
                  "text": "De travaux des pères fondateurs de la discipline.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Les « pères fondateurs » ? Et les « autres » ? Ce choix peut être problématique du point de vue <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>des rapports sociaux.</a>',
                  "categorie":['rapportSociaux']
                },
                {
                  "value": "D",
                  "text": "De travaux réalisés par des femmes dans mon domaine.",
                  "score": 1,
                  "cancelling": ["E", "F"],
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Y a-t-il d’autres minorités à rendre visibles ? Ce choix peut être problématique du point de vue des <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['rapportSociaux']
                },
                {
                  "value": "E",
                  "text": "De travaux réalisés dans différentes régions du monde, y compris dans les pays « du Sud ».",
                  "score": 1,
                  "cancelling": ["D", "F"],
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Y a-t-il d’autres minorités à rendre visibles ? Ce choix peut être problématique du point de vue des <a href=\'../Notice#mathilda\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['mathilda','sexisme']
                },
                {
                  "value": "F",
                  "text": "De travaux réalisés par des équipes dont je mentionne toujours les membres.",
                  "score": 0,
                  "cancelling": ["D", "E"],
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "G",
                  "text": "Je ne prête pas une attention particulière aux personnes qui ont réalisés les travaux ; seuls leurs résultats m’importent.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Vraiment ? Je trouve également intéressant de comprendre quand, où, par qui et comment les résultats ont été construits. Un tel choix me semble de plus pouvoir être problématique du point de vue des <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['rapportSociaux']
                },
                {
                  "value": "H",
                  "text": "Je ne mobilise pas de travaux antérieurs.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Vraiment ? Je trouve également intéressant de comprendre quand, où, par qui et comment les résultats ont été construits. Un tel choix me semble de plus pouvoir être problématique du point de vue des <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['rapportSociaux']
                }
              ]
            }
          ]
        },
        {
          "name": "page3",
          "elements": [
            {
              "type": "html",
              "name": "title3",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation fictive suivante :</p><p class='text-normal'>Vous recevez un courrier électronique du Rectorat, informant l’ensemble de la communauté HES-SO d’un événement consacré aux thématiques de l’égalité et de la diversité, telle qu’une semaine réservée à ce sujet dans l’année qui vient. Le personnel d’enseignement et de recherche est invité à participer à cet événement.</p><p class='text-title text-right'>Quel(s) type(s) de réaction(s) pourriez-vous avoir ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question3",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "Je ne souhaite pas participer.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Ce choix me révolte ! Ne pas participer, c’est reconduire des <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>discriminations.</a>',
                  "categorie":['discimi']
                },
                {
                  "value": "B",
                  "text": "Même si ces thématiques semblent intéressantes, elles ne concernent pas mon domaine. Je ne vais donc pas participer à cet événement.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Ce choix me révolte ! Ne pas participer, c’est reconduire des <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>discriminations.</a>',
                  "categorie":['discimi']
                },
                {
                  "value": "C",
                  "text": "Je ne sais pas où aller chercher de la matière pour construire un cours à ce sujet, je dois rapidement faire des recherches pour anticiper cette semaine.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "D",
                  "text": "Chercher de la matière ne sera pas compliqué, car j’intègre déjà des travaux de femmes dans mes cours.",
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Intégrer des travaux de femmes est intéressant du point de vue du sexisme. Y a-t-il d’autres minorités à rendre visibles ? Ce choix peut être problématique par exemple du point de vue du <a href=\'../Notice#racisme\' target=\'_blank\' rel=\'noopener noreferrer\'>racisme</a>, de la <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe sociale</a>, du <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitisme</a> et de <a href=\'../Notice#agisme\' target=\'_blank\' rel=\'noopener noreferrer\'>l’âgisme.</a>',
                  "categorie":['racisme','rapportSocialClasse','capacitisme','agisme']
                },
                {
                  "value": "E",
                  "text": "Je connais bien certaines inégalités mais veux me renseigner pour parler aussi d’autres que je connais moins bien, telles que le validisme, l’âgisme.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "F",
                  "text": "Je ne sais pas trop ce qu’on entend par ‘diversité’, mais je vais faire des recherches dans mon domaine pour construire un enseignement autour des chercheurs / de professionnels qui sont venus en aide à des femmes, dans d’autres régions du monde.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Mais cette approche de la thématique « égalité et diversité » contribue à reconduire des discriminations, par exemple <a href=\'../Notice#racisme\' target=\'_blank\' rel=\'noopener noreferrer\'>racistes</a>, <a href=\'../Notice#sexisme\' target=\'_blank\' rel=\'noopener noreferrer\'>sexistes</a>, <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes</a>, <a href=\'../Notice#agisme\' target=\'_blank\' rel=\'noopener noreferrer\'>âgistes</a> ou encore liées au rapport social de <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe.</a>',
                  "categorie":['racisme','sexisme','capacitisme','agisme', 'rapportSocialClasse']
                },
                {
                  "value": "G",
                  "text": "Je propose aux étudiant es et aux personnes noires de mon cours de monter une intervention pour cette semaine.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Cette approche participative est intéressante, mais je la trouve problématique du point de vue du <a href=\'../Notice#rapportPouvoirEnseignant\' target=\'_blank\' rel=\'noopener noreferrer\'>rapport de pouvoir entre personnes enseignantes et personnes aux études,</a> ainsi que des postulats <a href=\'../Notice#rapportSociaux\' target=\'_blank\' rel=\'noopener noreferrer\'>naturalisant</a> sur lesquels elle repose (et qu’elle contribue à perpétuer). ',
                  "categorie":['rapportPouvoirEnseignant','rapportSociaux']
                },
                {
                  "value": "H",
                  "text": "Pour bien combiner les deux thématiques, je vais proposer de parler de la complémentarité (entre hommes et femmes, entre suisses et étrangers, etc.) dans ma discipline.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Mais cette approche de la thématique « égalité et diversité » contribue à reconduire des <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>discriminations,</a> par exemple racistes, sexistes, capacitistes ou encore liées au rapport social de classe.',
                  "categorie":['discimi',]
                }
              ]
            }
          ]
        },
        {
          "name": "page4",
          "elements": [
            {
              "type": "html",
              "name": "title4",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>En début d’année académique, vous recevez de la part de votre décanat une demande d’adaptation de votre enseignement et des modalités d’examens concernant deux étudiant·es. Il s’agit d’accorder plus de temps pour la réalisation des travaux et dans le cadre de l’examen, de moins pénaliser les fautes d’orthographe et d’autoriser l’utilisation de moyens électroniques. De plus, vous recevez une demande d’un troisième étudiant, qui souhaite savoir s’il est possible de modifier la documentation à différents niveaux (taille, police, couleurs, moins de contenu par page, etc.).</p><p class='text-title text-right'>Sur cette dernière demande, quelle réaction serait la plus proche de la vôtre ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question4",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 1,
              "choices": [
                {
                  "value": "A",
                  "text": "Je ne peux pas faire des cours particuliers. Je rejette sa demande.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":1,
                  "scoreClasse":0,
                  "bulle": 'Cette réaction me choque : elle témoigne de discriminations <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes</a> : il ne s’agit pas de « faveurs ».',
                  "categorie":['capacitisme']
                },
                {
                  "value": "B",
                  "text": "Je lui dis de s’adresser au décanat : ce n’est pas de mon ressort.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":1,
                  "scoreClasse":0,
                  "bulle": 'Cette réaction me choque : elle témoigne de discriminations <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes</a> : il ne s’agit pas de « faveurs ».',
                  "categorie":['capacitisme']
                },
                {
                  "value": "C",
                  "text": "J’entre partiellement en matière, même si je trouve que ce type de demandes est un peu spécial, et trop courant.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":1,
                  "scoreClasse":0,
                  "bulle": 'Cette réaction me choque : elle témoigne de discriminations <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes</a> : il ne s’agit pas de « faveurs ».',
                  "categorie":['capacitisme']
                },
                {
                  "value": "D",
                  "text": "J’ai déjà des dispositifs d’enseignement qui sont prêts à être utilisés dans ce genre de cas. Je m’assure que cet étudiant ait informé le décanat de sa situation.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "E",
                  "text": "Il doit s’agir de mesures de compensation qui figurent dans la loi, pour des personnes ayant comme on le dit un « handicap », comme la dyslexie ou certaines formes d’autisme. J’aimerais me former pour être au courant des besoins de ces personnes, et entamer une réflexion sur les apports d’une approche inclusive pour la collectivité.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "F",
                  "text": "Bien sûr, je vais l’aider ! D’ailleurs, je propose toujours de l’aide à cet étudiant qui est en chaise et je tiens toujours la porte à ma collègue malvoyante.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":1,
                  "scoreClasse":0,
                  "bulle": 'Cette posture héroïque peut alimenter des discriminations <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes.</a> ',
                  "categorie":['capacitisme']
                }
              ]
            }
          ]
        },
        {
          "name": "page5",
          "elements": [
            {
              "type": "html",
              "name": "title5",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Un groupe d’étudiant·es vous demande l’autorisation de présenter une mesure d’aide par les pair·es au début de votre cours. Les personnes ciblées par cette mesure sont les étudiant·es qui rencontrent des risques d’échec, par exemple à la suite d’une absence prolongée (retour de maladie ou de congé maternité), les étudiant·es qui doivent travailler à un taux important à côté de leurs études, ou les personnes allophones. Le groupe recherche également des étudiant·es qui souhaiteraient s’engager dans cette activité de soutien par les pair·es.</p><p class='text-title text-right'>Quel(s) type(s) de réponse(s) pourriez-vous donner à une telle demande ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question5",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 4,
              "choices": [
                {
                  "value": "1",
                  "text": "Je n’entre pas en matière, je dois avancer dans mon programme.",
                  "score": 4,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":1,
                  "scoreClasse":1,
                  "bulle": 'Et pourtant ces mesures seraient utiles à plusieurs d’entre nous. Ne pas entrer en matière alimente les inégalités en matière de <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['discimi']
                },
                {
                  "value": "2",
                  "text": "Cette action a l’air très pertinente, je la soutiens et je leur laisse le temps nécessaire.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "3",
                  "text": "Je ne comprends pas bien de quoi il est question ou j’ai un avis mitigé :",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                }
              ]
            },
            {
              "type": "checkbox",
              "name": "question5_sub",
              "titleLocation": "hidden",
              "enableIf": "{question5} = ['3']",
              "visibleIf": "{question5} = ['3']",
              "isRequired": true,
              "maxScore": 4,
              "choices": [
                {
                  "value": "A",
                  "text": "Je leur accorde 2-3 minutes au début ou à la fin de mon cours.",
                  "score": 4,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":1,
                  "scoreClasse":1,
                  "bulle": 'Et pourtant ces mesures seraient utiles à plusieurs d’entre nous. Ne pas entrer en matière alimente les inégalités en matière de <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['discimi']
                },
                {
                  "value": "B",
                  "text": "Je leur propose de parler de ce sujet lors de rencontres d’étudiant es ou à la pause.",
                  "score": 4,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":1,
                  "scoreClasse":1,
                  "bulle": 'Et pourtant ces mesures seraient utiles à plusieurs d’entre nous. Ne pas entrer en matière alimente les inégalités en matière de <a href=\'../Notice#discimi\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['discimi']
                },
                {
                  "value": "C",
                  "text": "Je leur demande des précisions. Selon moi, une personne qui a été malade peut rattraper les cours. Ou en cas d’absence de longue durée, elle peut refaire son année.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":1,
                  "scoreClasse":0,
                  "bulle": 'Et pourtant rattraper des cours ou refaire l’année n’est pas évident pour tout le monde ! Douter de l’utilité de telles mesures alimente des discriminations <a href=\'../Notice#capacitisme\' target=\'_blank\' rel=\'noopener noreferrer\'>capacitistes.</a>',
                  "categorie":['capacitisme']
                },
                {
                  "value": "D",
                  "text": "Je leur demande des précisions. Je ne vois pas pourquoi des personnes allophones ayant choisi de se former dans cette école devraient recevoir de l’aide et pas les personnes locales.",
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Sympa pour les personnes allophones ! Ce raisonnement comporte une part de <a href=\'../Notice#rapportSocialRace\' target=\'_blank\' rel=\'noopener noreferrer\'>racisme.</a>',
                  "categorie":['rapportSocialRace']
                },
                {
                  "value": "E",
                  "text": "Je leur demande des précisions. Selon moi, tout le monde travaille à côté des études. Je ne comprends pas pourquoi il faudrait faire du favoritisme pour certains ou certaines.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'Le terme de « favoritisme » me heurte du point de vue de la <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe sociale</a> ! Ce n’est pas une « faveur » que d’aider les personnes qui ont moins de ressources.',
                  "categorie":['rapportSocialClasse']
                },
                {
                  "value": "F",
                  "text": "Je leur demande des précisions. Selon moi, aider les femmes qui reviennent de congé maternité, ce serait du sexisme à l’envers.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Du « sexisme à l’envers » ? Cette expression et les doutes qui vont avec me heurtent, car ils véhiculent des discriminations <a href=\'../Notice#rapportSocialSexe\' target=\'_blank\' rel=\'noopener noreferrer\'>sexistes.</a>',
                  "categorie":['rapportSocialSexe']
                }
              ]
            }
          ]
        },
        {
          "name": "page6",
          "elements": [
            {
              "type": "html",
              "name": "title6",
              "html": {
                "fr": "<h1 class='text-title'>Pendant l'enseignement</h1><p class='text-title'>Imaginez la situation (réelle ou fictive) suivante :</p><p class='text-normal'>Lors de vos cours, vous constatez que les étudiant es prennent volontiers la parole, alors que les étudiant es y interviennent peu, voire pas du tout.</p><p class='text-title text-right'>Qu’entreprenez-vous ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question6",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 1,
              "choices": [
                {
                  "value": "A",
                  "text": "Je ne fais rien de particulier et je m’attends à ce qu’elles prennent la parole spontanément ; cela fait partie des dynamiques des cours.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Comment cela se fait-il ? Selon les circonstances, il est difficile pour certaines femmes de prendre la parole en public. Ne rien faire pour modifier la dynamique des cours relève d’une forme de <a href=\'../Notice#rapportSocialSexe\' target=\'_blank\' rel=\'noopener noreferrer\'>sexisme.</a>',
                  "categorie":['rapportSocialSexe']
                },
                {
                  "value": "B",
                  "text": "J’ai le souci qu’elles puissent s’exprimer et je les interroge très fréquemment ; je les félicite le plus possible pour les valoriser.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'C’est un début ! Des encouragements peuvent favoriser la prise de parole en public, mais le <a href=\'../Notice#sexismeHosBienAmb\' target=\'_blank\' rel=\'noopener noreferrer\'>sexisme bienveillant</a> n’aide pas.',
                  "categorie":['sexismeHosBienAmb']
                },
                {
                  "value": "C",
                  "text": "J’impose une procédure de contrôle concernant la prise de parole (par exemple, je définis un tour de parole).",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "D",
                  "text": "Les hommes prennent naturellement plus de place que les femmes. Ce n’est pas un problème.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Si, c’est un problème ! Ce raisonnement repose sur un postulat <a href=\'../Notice#sexismeHosBienAmb\' target=\'_blank\' rel=\'noopener noreferrer\'>sexiste.</a> Il ne tient pas compte des obstacles que rencontrent les femmes en matière de prise de parole.',
                  "categorie":['sexismeHosBienAmb']
                },
                {
                  "value": "E",
                  "text": "Je ne sais pas comment résoudre cette difficulté et cherche des ressources pour y remédier.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                }
              ]
            }
          ]
        },
        {
          "name": "page7",
          "elements": [
            {
              "type": "html",
              "name": "title7",
              "html": {
                "fr": "Il arrive que des personnes participant à votre enseignement posent des questions ou donnent des réponses qui vous paraissent peu pertinentes ou dans un vocabulaire qui vous paraît inapproprié.<br/><br/><b>Dans ce type de situations, comment réagissez-vous ?</b>"
              }
            },
            {
              "type": "checkbox",
              "name": "question7",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 1,
              "choices": [
                {
                  "value": "A",
                  "text": "J’ignore la question ou la réponse et poursuis mon enseignement.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'J’essaie de progresser dans mes études, mais c’est difficile de garder ma motivation lorsque la personne qui enseigne m’ignore. Ce type de réaction produit des discriminations au niveau de la <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe sociale.</a>',
                  "categorie":['rapportSocialClasse']
                },
                {
                  "value": "B",
                  "text": "Lorsque cela se reproduit trop souvent, j’évite de donner la parole à ces personnes et privilégie les échanges avec celles qui sont brillantes et celles qui travaillent.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'J’essaie de progresser dans mes études, mais c’est difficile de garder ma motivation lorsque la personne qui enseigne m’ignore. Ce type de réaction produit des discriminations au niveau de la <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe sociale.</a>',
                  "categorie":['rapportSocialClasse']
                },
                {
                  "value": "C",
                  "text": "J’indique rapidement les erreurs de l’étudiant·e, les corrige et passe à la suite.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'J’essaie de progresser dans mes études, mais c’est difficile de garder ma motivation lorsque la personne qui enseigne passe trop rapidement sur les problèmes rencontrés. Ce type de réaction produit des discriminations au niveau de la <a href=\'../Notice#rapportSocialClasse\' target=\'_blank\' rel=\'noopener noreferrer\'>classe sociale.</a>',
                  "categorie":['rapportSocialClasse']
                },
                {
                  "value": "D",
                  "text": "Après avoir remercié les étudiant·es pour leurs questions ou leurs réponses, je propose des ajustements, voire des corrections qui me paraissent nécessaires.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "E",
                  "text": "Je tente de trouver un point positif ou nouveau à partir de la question ou de la réponse donnée, puis développe pour apporter un contenu pertinent.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "F",
                  "text": "Je donne la parole aux autres personnes pour répondre, compléter, corriger.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "G",
                  "text": "Je répertorie la question / la réponse dans mes notes, à des fins d’amélioration de mon enseignement.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                }
              ]
            }
          ]
        },
        {
          "name": "page8",
          "elements": [
            {
              "type": "html",
              "name": "title8",
              "html": {
                "fr": "<p class='text-title'>Quelle est votre utilisation du langage inclusif ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question8",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "J’utilise le langage épicène la plupart du temps. Dans la mesure du possible j’essaie d’employer un langage inclusif, en parlant par exemple de « personnes aux études », en utilisant le gender gap (« étudiant_e ») ou le pronom « iel ».",
                  "cancelling": ["C"],
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cela suffit-il ? Ce choix tient compte du rapport de genre, mais d’autres <a href=\'../Notice#inclusif\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux</a> peuvent être pris en considération !',
                  "categorie":['inclusif']
                },
                {
                  "value": "B",
                  "text": "Je n’utilise jamais, je ne vois pas l’intérêt ou je suis contre.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cette réaction me heurte vraiment ! Elle alimente des discriminations relevant de plusieurs <a href=\'../Notice#inclusif\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['inclusif']
                },
                {
                  "value": "C",
                  "text": "Je fais surtout attention à ne pas utiliser des expressions et termes racistes (« Ce n’est jamais tout blanc ou tout noir. », « C’est du chinois ce que tu racontes. », « le travail au noir », etc.).",
                  "cancelling": ["A"],
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cela suffit-il ? Ce choix tient compte des conséquences du langage en termes de racisme, mais les autres <a href=\'../Notice#inclusif\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux</a> sont moins pris en compte.',
                  "categorie":['inclusif']
                },
                {
                  "value": "D",
                  "text": "J’utilise « personne en situation de handicap » à la place de « handicapé » et « ethnie » à la place de « race », « différence de genres » à la place de « différence de sexes ».",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Cela suffit-il ? Remplacer un mot par un autre n’est pas une solution ! En appliquant des formules sur le langage, on risque d’alimenter des discriminations relevant de plusieurs <a href=\'../Notice#inclusif\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['inclusif']
                },
                {
                  "value": "G",
                  "text": "J’ignore de quoi il s’agit.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Lorsqu’on ne connaît pas les conséquences du langage, on risque d’alimenter des discriminations relevant de plusieurs <a href=\'../Notice#inclusif\' target=\'_blank\' rel=\'noopener noreferrer\'>rapports sociaux.</a>',
                  "categorie":['inclusif']
                }
              ]
            }
          ]
        },
        {
          "name": "page9",
          "elements": [
            {
              "type": "html",
              "name": "title9",
              "html": {
                "fr": "<h1 class='text-title'>Après l'enseignement</h1><p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Votre collègue vous confie que des étudiant·es s’insultent régulièrement en employant des termes tels que « pute » et « pédé ». Cette situation lui paraît source de discriminations, mais il lui est difficile de savoir dans quelle mesure et comment intervenir.</p><p class='text-title text-right'>Qu’en pensez-vous ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question9",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "Je lui conseille d’éviter d’entrer dans leurs échanges. Si cela se déroule dans les moments de pause, cela ne nous concerne pas.",
                  "score": 2,
                  "scoreRacisme":0,
                  "scoreSexisme":2,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Sympa pour les personnes visées par ces insultes ! Un contexte <a href=\'../Notice#harcelement\' target=\'_blank\' rel=\'noopener noreferrer\'>oppressif</a> n’est pas idéal pour apprendre.',
                  "categorie":['harcelement']
                },
                {
                  "value": "B",
                  "text": "C’est du deuxième degré ! Et puis, si tout le monde rigole, pourquoi en faire un plat ?",
                  "score": 2,
                  "scoreRacisme":0,
                  "scoreSexisme":2,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Sympa pour les personnes visées par ces insultes ! <a href=\'../Notice#harcelement\' target=\'_blank\' rel=\'noopener noreferrer\'>L’humour oppressif</a> n’est pas le meilleur contexte pour apprendre.',
                  "categorie":['harcelement']
                },
                {
                  "value": "C",
                  "text": "L’expression « pédé » me dérange car elle est homophobe.",
                  "cancelling": ["D", "E"],
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Oui, je la trouve aussi problématique ! Mais l’expression « pute » n’est pas franchement dénuée de sexisme… <a href=\'../Notice#harcelement\' target=\'_blank\' rel=\'noopener noreferrer\'></a>',
                  "categorie":['harcelement']
                },
                {
                  "value": "D",
                  "text": "L’expression « pute » me dérange car elle est sexiste.",
                  "cancelling": ["C", "E"],
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Oui, je la trouve aussi problématique ! Mais l’expression « pédé » discrimine des personnes sur la base de l’image qu’on se fait de leurs pratiques sexuelles ! <a href=\'../Notice#harcelement\' target=\'_blank\' rel=\'noopener noreferrer\'></a>',
                  "categorie":['harcelement']
                },
                {
                  "value": "E",
                  "text": "Je lui conseille d’en discuter avec toute sa classe car ces situations sont des expressions de sexisme et d’homophobie que notre institution ne doit pas admettre et reconduire.",
                  "cancelling": ["C", "D"],
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                }
              ]
            }
          ]
        },
        {
          "name": "page10",
          "elements": [
            {
              "type": "html",
              "name": "title10",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Dans le cadre d’un séminaire, un groupe présente un travail mené sur le thème de la famille. Le support de présentation inclut la photographie suivante.</p><img class='quiz-img' src='img/illu_Q10.jpg' alt='illu_Q1'/><p class='text-italic text-center'>Le Journal International, David Wanedam</p><p class='text-title text-right'>Pensez-vous la commenter ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question10",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "Non, je n’ai pas de commentaire à son sujet.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "B",
                  "text": "Oui, c’est tellement important de représenter la famille authentique africaine.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": '« La famille authentique africaine » ? Merci pour les <a href=\'../Notice#rapportSocialRace\n\' target=\'_blank\' rel=\'noopener noreferrer\'>stéréotypes racistes</a> ! Sans compter les <a href=\'../Notice#normeBlanche\' target=\'_blank\' rel=\'noopener noreferrer\'>stéréotypes de classe</a> !',
                  "categorie":['rapportSocialRace','normeBlanche']
                },
                {
                  "value": "C",
                  "text": "Cette image me paraît un peu trop « particulière » pour illustrer un travail sur la famille.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'Parce que la famille « normale », c’est quoi ? Un homme blanc, une femme blanche et deux enfants blancs – une fille et un garçon de préférence ? La qualification de « particulière » m’évoque une <a href=\'../Notice#normeBlanche\' target=\'_blank\' rel=\'noopener noreferrer\'>norme blanche et bourgeoise.</a>',
                  "categorie":['normeBlanche']
                },
                {
                  "value": "D",
                  "text": "Cette image représente-t-elle vraiment une famille ? Il y a visiblement plus de deux adultes (un père, mais qui est la mère ?), et trop d’enfants pour que ce soient les leurs.",
                  "score": 2,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":1,
                  "bulle": 'C’est peut-être une famille malgré ces caractéristiques ! Après tout, les familles peuvent prendre <a href=\'../Notice#normeBlanche\' target=\'_blank\' rel=\'noopener noreferrer\'>différentes formes,</a> comme les familles recomposées par exemple !',
                  "categorie":['normeBlanche']
                }
              ]
            }
          ]
        },
        {
          "name": "page11",
          "elements": [
            {
              "type": "html",
              "name": "title11",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Un étudiant vous informe que lorsqu’un travail de groupe est demandé, il se trouve toujours avec les cinq autres étudiants qui ne sont pas « blancs », et que cette situation d’un groupe de travail entièrement racisé n’est pas souhaitée par eux.</p><p class='text-title text-right'>Qu’entreprenez-vous ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question11",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 1,
              "choices": [
                {
                  "value": "A",
                  "text": "Cette situation ne me paraît pas bien grave. Je leur demande de s’arranger entre eux.",
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Ne pas avoir remarqué le problème est une chose, mais l’ignorer lorsqu’on vous en informe, c’est cautionner cette forme de <a href=\'../Notice#racisme\' target=\'_blank\' rel=\'noopener noreferrer\'>racisme !</a>',
                  "categorie":['racisme']
                },
                {
                  "value": "B",
                  "text": "J’aborde le sujet de la constitution des groupes devant toute la classe lors du prochain travail de ce type.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "C",
                  "text": "Je demande à l’étudiant d’exposer son souci devant la classe lors du prochain travail de groupe. Il sera plus à même d’expliquer son problème.",
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Si l’étudiant a choisi d’en parler avec vous, le contraindre à s’expliquer devant toute la classe est une <a href=\'../Notice#rapportSocialRace\' target=\'_blank\' rel=\'noopener noreferrer\'>violence !</a>',
                  "categorie":['rapportSocialRace']
                },
                {
                  "value": "D",
                  "text": "J’envisage de mettre en place des consignes précises afin de réguler explicitement la construction des groupes, comme par exemple varier la composition des groupes d’une fois à l’autre ou au cours d’une session de travail en groupe.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "E",
                  "text": "Désormais, j’impose que tous les groupes de travail soient constitués d'au moins une personne racisée.",
                  "score": 1,
                  "scoreRacisme":1,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Quelle solution… Les choses ne sont pas aussi simples : c’est aussi une <a href=\'../Notice#rapportSocialRace\' target=\'_blank\' rel=\'noopener noreferrer\'>violence</a> d’imposer de tels groupes ! Et puis sur quelle base une personne est-elle « racisée » ?',
                  "categorie":['rapportSocialRace']
                }
              ]
            }
          ]
        },
        {
          "name": "page12",
          "elements": [
            {
              "type": "html",
              "name": "title12",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Suite aux évaluations qualité de votre enseignement, vous vous rendez compte une fois un atelier terminé que vous avez à plusieurs reprises offensé une étudiante transgenre en utilisant son prénom officiel et en accordant les adjectifs en accord avec le prénom.</p><p class='text-title text-right'>Qu’entreprenez-vous ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question12",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 1,
              "choices": [
                {
                  "value": "A",
                  "text": "Je continue néanmoins d’employer le prénom officiel de la personne.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": '<a href=\'../Notice#heterosexisme\' target=\'_blank\' rel=\'noopener noreferrer\'>Petite base </a>à l’attention des personnes qui ne connaissent pas cette problématique : si vous voulez éviter d’oppresser, utilisez les noms, prénom, pronoms que la personne vous demande d’utiliser.',
                  "categorie":['heterosexisme']
                },
                {
                  "value": "B",
                  "text": "Je fais mon mea culpa à cette personne devant toute la classe.",
                  "score": 1,
                  "scoreRacisme":0,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Se sentir coupable ne doit pas conduire à commettre d’autres violences : en parler devant la classe est de l’ordre de <a href=\'../Notice#heterosexisme\' target=\'_blank\' rel=\'noopener noreferrer\'>l’outing.</a>',
                  "categorie":['heterosexisme']
                },
                {
                  "value": "C",
                  "text": "Je prends contact avec cette personne pour m’excuser auprès d’elle.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "D",
                  "text": "Je ne connaissais pas cette problématique. Je me documente pour bien comprendre les enjeux et avoir plus de vigilance à ce sujet.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                }
              ]
            }
          ]
        },
        {
          "name": "page13",
          "elements": [
            {
              "type": "html",
              "name": "title13",
              "html": {
                "fr": "<p class='text-title'>Imaginez la situation suivante :</p><p class='text-normal'>Catastrophe ! Vous vous rendez compte après le cours que vous avez utilisé un exemple à la fois raciste et sexiste.</p><p class='text-title text-right'>\nQu’entreprenez-vous ?</p>"
              }
            },
            {
              "type": "checkbox",
              "name": "question13",
              "choicesOrder": "random",
              "titleLocation": "hidden",
              "isRequired": true,
              "maxScore": 2,
              "choices": [
                {
                  "value": "A",
                  "text": "Si des étudiant·es abordent ce point, je les féliciterai de leur vigilance, mais sinon je ne fais rien de particulier.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'Et si on remarque et qu’on ne dit rien ? Je m’attends à une discussion à propos du <a href=\'../Notice#racisme\' target=\'_blank\' rel=\'noopener noreferrer\'>racisme</a> et du <a href=\'../Notice#sexisme\' target=\'_blank\' rel=\'noopener noreferrer\'>sexisme</a> avec la classe, pas à des félicitations.',
                  "categorie":['racisme','sexisme']
                },
                {
                  "value": "B",
                  "text": "Je reviens sur ma maladresse lors du cours suivant : je ne veux pas contribuer à reproduire de tels discours.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "C",
                  "text": "Je profiterai de reprendre l’exemple au cours suivant et de montrer les difficultés de trouver des bons exemples. Je discuterai également des implications pour les professions liées à leur formation.",
                  "score": 0,
                  "scoreRacisme":0,
                  "scoreSexisme":0,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": ''
                },
                {
                  "value": "D",
                  "text": "On ne peut pas faire attention à tout, sinon on ne peut plus rien dire.",
                  "score": 2,
                  "scoreRacisme":1,
                  "scoreSexisme":1,
                  "scoreCapacitisme":0,
                  "scoreClasse":0,
                  "bulle": 'On ne peut pas faire attention à tout … Mais la personne qui enseigne a une responsabilité vis-à-vis de la classe en matière de vigilance face au <a href=\'../Notice#racisme\' target=\'_blank\' rel=\'noopener noreferrer\'>racisme</a> et au <a href=\'../Notice#sexisme\' target=\'_blank\' rel=\'noopener noreferrer\'>sexisme.</a>',
                  "categorie":['racisme','sexisme']
                }
              ]
            }
          ]
        }
      ],
      "showPrevButton": false,
      "showQuestionNumbers": "off",
      "showProgressBar": "bottom",
      "firstPageIsStarted": true,
    };

    var survey = new SurveyVue.Model(json);
    var totalScorePrevious = 0;
    var totalScore = 0;
    var scoreRacisme = 0;
    var scoreSexisme = 0;
    var scoreCapacitisme = 0;
    var scoreClasse = 0;
    var numQuestion = 0 ;
    var vm = this;
    var qmsg = "";
    var tabCategorie = [];

     var today = new Date();
     var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
     var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
     var dateTime = date+' '+time;


    //a voir si on mets toutes les réponses random
    /*var q = survey.getQuestionByName('question1');
    q.choicesOrder = "random";*/

        //event triggered when users clicks on next button
    survey.onCurrentPageChanging.add( function (survey, options) {
      //console.log("survey.onCurrentPageChanging");

      makePionsLeave(survey, options);
      //delayedGreeting(survey, options);
    });
    function calculateTotalScore(survey) {
      var rtotalScore = 0;
      var data = survey.data;
      var rscoreRacisme = 0 ;
      var rscoreSexisme = 0 ;
      var rscoreCapacitisme = 0 ;
      var rscoreClasse = 0 ;

      var rmsg  = '';
      var rname = '';

      Object.keys(data).forEach(function (qName) {
        var question = survey.getQuestionByName(qName);
        var qValue = data[qName];
        var maxPoints = question.maxScore;
        var qTotalScore = 0;
        var qCancelled = false;
        var temprscoreRacisme = 0 ;
        var temprscoreSexisme = 0 ;
        var temprscoreCapacitisme = 0 ;
        var temprscoreClasse = 0 ;
        var tempTabCategorie = [];
        rname = qName

        if (question.choices) {
          question.choices.forEach(function (choice) {
            if(qCancelled) return;

            //if the response has been selected
            if (qValue.includes(choice.value)) {
              //check if one of the questions cancelling points is in the selected answers too
              var cancelled = choice.cancelling ? choice.cancelling.some((el) => qValue.includes(el)) : false;

              if (cancelled) {
                //Quand il y a une annulation on met le score de l’annulation (souvent 0)
                qCancelled = true;
                //console.log("qCancelled = " + qCancelled);
                console.log("cancelled = " + cancelled);
                return;
              }

              if (qTotalScore >= maxPoints) return;
              //if not, adds the points

              if (!cancelled) {
                //rtotalScore += choice.score;
                console.log("q choice = " + choice.value);
                qTotalScore += choice.score;

                temprscoreRacisme += choice.scoreRacisme;
                temprscoreSexisme += choice.scoreSexisme;
                temprscoreCapacitisme += choice.scoreCapacitisme;
                temprscoreClasse += choice.scoreClasse;
                rmsg = choice.bulle;

                if (choice.categorie) {
                  choice.categorie.forEach(element => tabCategorie.indexOf(element) === -1 ? tempTabCategorie.push(element) : console.log("This item already exists"));
                  //console.log("tempTAB" + tempTabCategorie);
                }

              }
            }
          });
        }
        if(!qCancelled) {
          //Quand il y a une annulation on met le score de l’annulation (souvent 0)
          //console.log("Cancelled : " + qCancelled + "-----2-----");
          console.log("adding to score");
          if (qTotalScore >= maxPoints) {
            rtotalScore += maxPoints;

          } else {
            rtotalScore += qTotalScore;
          }
          //console.log(temprscoreRacisme);
          //console.log(rscoreRacisme);

          rscoreRacisme += temprscoreRacisme;
          rscoreSexisme += temprscoreSexisme;
          rscoreCapacitisme += temprscoreCapacitisme;
          rscoreClasse += temprscoreClasse;

          //console.log(tempTabCategorie);
          tempTabCategorie.forEach(element => tabCategorie.indexOf(element) === -1 ? tabCategorie.push(element) : console.log("This item already exists"));
        }
      });
      return [rtotalScore, rscoreRacisme, rscoreSexisme, rscoreCapacitisme, rscoreClasse, rmsg, rname];
    }

    function calculateCurrentPageScore(survey) {
      var currentPageScore = 0;
      var tab = calculateTotalScore(survey);
      totalScore = tab[0];
      scoreRacisme = tab[1];
      scoreSexisme = tab[2];
      scoreCapacitisme = tab[3];
      scoreClasse = tab[4];
      qmsg = tab[5];

      currentPageScore = totalScore - totalScorePrevious;
      totalScorePrevious = totalScore;
      numQuestion = tab[6];
      console.log("Question numéro : " + numQuestion);
      //("msg : " + qmsg);
      console.log("Page score : " + currentPageScore);
      console.log("Total score : " + totalScore);
      console.log("scoreRacisme : " + scoreRacisme);
      console.log("scoreSexisme : " + scoreSexisme);
      console.log("scoreCapacitisme : " + scoreCapacitisme);
      console.log("scoreClasse : " + scoreClasse);
      console.log("catégorie  : " + tabCategorie);
      return currentPageScore;
    }

    function makePionsLeave(survey, options) {
      options.allowComplete = false;
      //les pions quittent la salle
      let pionsLeaving = calculateCurrentPageScore(survey);
      console.log("nombre qui quitte " + pionsLeaving);
      if(pionsLeaving > 0){
       needBubble(survey, options);
       options.allowChanging = false;

       //play sound
      document.getElementById('door-sound').play();

      options.allowComplete = true;
      document.getElementById("surveyContainer")
      document.getElementById("surveyContainer").style.display = 'block';
      vm.updatePionCount(pionsLeaving);
      }

      options.allowComplete = true;

    }

    async function needBubble(survey, options){
      //console.log("Question mes" + qmsg);

      await sleep(1000);
      store.commit('newVignette', qmsg);

      //console.log(store.getters.vignetteRandom);
      //console.log(store.getters.seenState);
      //console.log(store.getters.text_msg_state);

      delayedGreeting(survey, options);
    }

    survey.onCompleting.add(function (survey, options) {
      //console.log("survey.onCompleting.");
      makePionsLeave(survey, options);
    });

    survey
        .onComplete
        .add(function (result) {
          const db = firebase.firestore();
          db.collection("results")
                  .add({ answers: JSON.stringify(result.data, null, 3),
                    score: totalScore,
                    scoreRacisme: scoreRacisme,
                    scoreSexisme: scoreSexisme,
                    scoreCapacitisme: scoreCapacitisme,
                    scoreClasse: scoreClasse,
                    date: dateTime
                  }).then(() => {
                    console.log("Document successfully written!");
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);
                  });
          var myArrayREF = {rapportSociaux: "Rapports sociaux",intersectionnalite: "Intersectionnalité – approche intersectionnelle",stereo_pre_discri: "Stéréotypes, préjugés, discriminations",stereotypes: "Stéréotypes",prejuges: "Préjugés",discimi: "Discrimination",harcelement: "Harcèlement, humour oppressif",inclusif: "Langage inclusif",rapportSocialRace: "Rapport social de race",racisme: "Racisme",xenophobie: "Xénophobie",normeBlanche: "Norme blanche bourgeoise",biaisBlanc: "Biais blanc masculin bourgeois",postcolonial: "Postcolonial",effetEveAfricain: "Effet Ève Africaine",racismeGenre: "Racisme genré",rapportSocialSexe: "Rapport social de sexe",genre: "Genre et études genre",cv: "Curriculum caché",sexismeHosBienAmb: "Sexisme hostile et sexisme bienveillant – sexisme ambivalent",sexismeFormation: "Sexisme dans le cadre de la formation",androcentrisme: "Androcentrisme",mathilda: "Effet Mathilda",sexisme: "Sexisme",heterosexisme: "Hétéro-cis-sexisme",cisnormativite: "Cisnormativité, cissexisme, cisgenrisme",pratiqueEnseignement: "Pratiques respectueuses dans l’enseignement",rapportSocialClasse: "Rapport social de classe",matthieu: "Effet Matthieu",capacitisme: "Capacitisme",mesuresCompensatoires: "Mesures compensatoires",postureHeroique: "Posture héroïque",agisme: "Âgisme",definitionAnthropologique: "Une définition anthropologique",rapportPouvoirEnseignant: "Rapports de pouvoir entre personnes enseignantes et personnes aux études",constructionConnaissance: "Construction des sciences / art / connaissance"};
          var html ="<ul>"
          tabCategorie.forEach(element => html+="<li class='notice-link'><a href='../Notice#"+element+"' target='_blank' rel='noopener noreferrer'>"+myArrayREF[element]+"</a><br></li>");
          html += "</ul>"

          document
              .querySelector('#surveyResult')
                  .innerHTML = "<h1>Vous avez perdu <span class='text-orange'>" + JSON.stringify(totalScore) + "</span> élève(s) \n sur 23 </h1>" +
                  "<ul> <li class='category-list'><span>" + JSON.stringify(scoreRacisme) + "</span> élève(s) à cause du rapport social de race </li>" +
                  "<li class='category-list'><span>" + JSON.stringify(scoreSexisme) + "</span> élève(s) à cause du rapport social de sexe </li>" +
                  "<li class='category-list'><span>" + JSON.stringify(scoreClasse) + "</span> élève(s) à cause du rapport social de classe </li>" +
                  "<li class='category-list'><span>" + JSON.stringify(scoreCapacitisme) + "</span> élève(s) à cause du capacitisme </li> </ul>" +
                  "<a class='v-btn v-btn--contained theme--light v-size--default' style='background-color: #f16d0b;color: white;margin-left: 85%;' href='/quiz' class='nav-link'> Rejouer </a>"
                  + "<br><h2>Les liens vers les notices : </h2>" + html;


        });
     survey
        .onAfterRenderQuestion
        .add(function(sender, opt) {
          var el = opt.htmlElement;
          var question = opt.question;
          var sv = el.parentElement.parentElement;

          //console.log("onAfterRenderQuestion");

          if (question.getType() == 'checkbox') {
            sv.className = sv.className + " wrapped";
          }
        });

     function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      async function delayedGreeting(survey, options) {
          while (store.getters.seenState) {
            await sleep(1000);
          }
          options.allowChanging = true;
          survey.nextPage();
      }

    return {
      survey: survey,
      pionCounter: 23, //max 23 pion à perdre selon le doc
      thumbnail: '',
      text_msg: store.getters.text_msg_state,
      mySeen: store.getters.seenState
    };
  },
  methods: {
    updatePionCount: function (pionLeaving) {
      console.log("updatePionCount");
      this.pionCounter = this.pionCounter - pionLeaving;

    },
    getThumbnail() {
      //("thumb" + store.getters.vignetteRandom);
      //console.log(store.getters.seenState);
      return store.getters.vignetteRandom;
    },
    getSeenState(){
      return store.getters.seenState;
    },
    getBulleMsg(){
      //console.log("text msg "+ store.getters.text_msg_state);
      return store.getters.text_msg_state;
    }
  }
};
</script>
<style scoped>
  .dv-container {
    height: 75vh;
  }
  .slither-enter-active, .slither-leave-active {
    transition: transform 5s;
  }
  .slither-enter, .slither-leave-to {
    transform: translateX(100vw);
  }
  .slither-enter-to, .slither-leave {
    transform: translateX(0);
  }
  @media (min-width: 900px) {
    >>> .sv_start_btn {
      position: fixed;
      top: 110px;
      right: 90px;
      font-size: larger !important;
      margin-top: 12px !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }
</style>
