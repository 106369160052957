<template>
    <v-container class="dv-container">
        <v-row>
            <v-col cols="12" md="9" class="pr-md-4">
                <h1>« Dans la vie de… »</h1>
                <div class="mb-6 mt-4">Objectifs de l’activité pédagogique inspirée du jeu "Un pas en avant" :
                    <ul>
                        <li>Expérimenter ce que peuvent ressentir, penser et vivre des personnes qui sont victimes de discriminations et/ou celles qui bénéficient de certains privilèges sociaux en se mettant « dans la vie » de personnages décrits sur des vignettes ;</li>
                        <li>Percevoir les discriminations et privilèges qui caractérisent sa position sociale et favoriser ainsi des pratiques égalitaires.</li>
                    </ul>
                </div>
              <p>Cette activité pédagogique doit se mettre en place et se pratiquer en conditions naturelles, dans une salle de classe ou à l’extérieur. Pour réaliser cette activité, il convient de télécharger le jeu et les fiches de rôles qui l’accompagnent. </p>
                <v-stepper
                        v-model="e6"
                        vertical >
                    <v-stepper-step
                            :complete="e6 > 1"
                            step="1"
                            editable >
                        Étape 1
                        <small></small>
                    </v-stepper-step>

                    <v-stepper-content step="1">
                        <div>
                            Une première étape comprend la découverte de la fiche de rôle (vignette) qui vous est attribuée. Pendant la suite du jeu, il vous est demandé de quitter votre propre personne pour incarner la personne décrite sur la fiche de rôle reçue. Un temps est consacré à l’imprégnation du descriptif. Pour cette tâche une série de questions vous est proposée, qui est destinée à vous aider à « quitter » votre propre personne pour « entrer » dans le personnage dont vous avez reçu la vignette.
                        </div>
                        <v-btn
                                color="primary"
                                @click="e6 = 2"
                                class="mt-2 mb-2 font-weight-bold"
                                text >
                            Suivant
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 2"
                            step="2"
                            editable >
                        Étape 2
                        <small></small>
                    </v-stepper-step>

                    <v-stepper-content step="2">
                        <div>
                            Une deuxième étape comprend le cœur du jeu, à savoir le moment où vous ferez (ou non) des « pas en avant ». A ce moment, des affirmations vous seront proposées. Lorsque l’affirmation vous semblera s’appliquer à propos du personnage que vous incarnez, vous ferez un pas en avant. Lorsqu’elle vous paraîtra moins crédible concernant le personnage que vous incarnez ou lorsqu’elle vous paraîtra ne pas pouvoir s’appliquer, vous resterez sur place. En cas d’hésitation après une affirmation, gardez à l’esprit qu’il n’y a pas de réponse juste ou fausse et tentez d’extrapoler d’après le descriptif et selon votre imagination.
                        </div>
                        <v-btn
                                color="primary"
                                @click="e6 = 3"
                                class="mt-2 mb-2 font-weight-bold"
                                text >
                            Suivant
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step
                            :complete="e6 > 3"
                            step="3"
                            editable >
                        Étape 3
                        <small></small>
                    </v-stepper-step>

                    <v-stepper-content step="3">
                        <div>
                            Une troisième étape comprend un moment d’échanges après le jeu. Tout gardant à l’esprit le personnage incarné, vous pourrez échanger sur les émotions que vous avez ressenties durant la partie précédente du jeu. Vous pouvez dévoiler le personnage que vous avez incarné, en fonction de ce que vous souhaitez partager avec les autres personnes participant à l’activité. Au fur et à mesure des questions, vous pourrez progressivement « sortir » de votre personnage et revenir dans votre « propre peau ».
                        </div>
                        <v-btn
                                color="primary"
                                @click="e6 = 4"
                                class="mt-2 mb-2 font-weight-bold"
                                text >
                            Suivant
                        </v-btn>
                    </v-stepper-content>
                </v-stepper>

            </v-col>
            <v-col cols="12" md="3" class="">
                <div>
                    <h4 class="mb-4">Téléchargements</h4>
                    <v-btn
                            href="/files/HETSL_dans-la-vie-de.pdf"
                            download
                            color="#2c3e50"
                            class="mb-3" >
                        Le jeu  <font-awesome-icon icon="file-pdf" class="pl-2 dv-btn-icon" />
                    </v-btn>
                    <v-btn
                            href="/files/HETSL_vignettes.pdf"
                            download
                            color="#2c3e50" >
                        Les fiches de rôle  <font-awesome-icon icon="address-card" class="pl-2 dv-btn-icon" />
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Wp1",
        data() {
            return {
                e6: 1,
            }
        }
    }
</script>

<style scoped>
    button, .v-btn {
        color: white !important;
    }
</style>