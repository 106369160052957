<template>
  <v-container>
    <v-row>
      <v-dialog v-if="isOpen" :value="dialog" @input="v => v || doSomething()" >
        <v-card>
          <v-container fluid>
            <v-row dense>
              <v-spacer></v-spacer>
              <v-col class="text-right pr-4">
                <a @click="doSomething()">
                  <font-awesome-icon icon="times" class="pl-2 dv-btn-icon grey--text" />
              </a>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-center">
                <img :src="'img/vignettes/'+vignette+'.png'" width="150">
              </v-col>
              <v-col cols="8" class="mt-2">
                <div class="triangle-border left">
                  <p v-html="message"></p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store/index";

//var mySeen = store.getters.seen;
//console.log("mySeen" +mySeen );

export default {
  name: "Thumbnail.vue",
  props: {
    vignette: String,
    message: String,
    dialog:Boolean
  },
  data() {
    return {

    }
  },
  methods: {
    getThumbnail() {
      //store.commit('newVignette')
      //console.log(store.getters.vignetteRandom);

      return store.getters.vignetteRandom;
    },
    doSomething(){
      store.commit('newVignette');
    },
    isOpen(){
      return store.getters.seenState;
    }
  }
}
</script>

<style scoped>
  /* ============================================================================================================================
  == BUBBLE WITH A BORDER AND TRIANGLE
  ** ============================================================================================================================ */

  /* THE SPEECH BUBBLE
  ------------------------------------------------------------------------------------------------------------------------------- */
  .triangle-border {
    position:relative;
    padding:15px 20px;
    margin:1em 0 3em;
    border:1px solid black;
    color:#333;
    background:#fff;
    /* css3 */
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    border-radius:50px;
  }
  /* Variant : for left positioned triangle
  ------------------------------------------ */
  .triangle-border.left {
    margin-left:20px;
  }
  /* THE TRIANGLE
  ------------------------------------------------------------------------------------------------------------------------------- */
  .triangle-border:before {
    content:"";
    position:absolute;
    bottom:-20px; /* value = - border-top-width - border-bottom-width */
    left:40px; /* controls horizontal position */
    border-width:20px 20px 0;
    border-style:solid;
    border-color:#5a8f00 black;
    /* reduce the damage in FF3.0 */
    display:block;
    width:0;
  }
  /* creates the smaller  triangle */
  .triangle-border:after {
    content:"";
    position:absolute;
    bottom:-13px; /* value = - border-top-width - border-bottom-width */
    left:47px; /* value = (:before left) + (:before border-left) - (:after border-left) */
    border-width:13px 13px 0;
    border-style:solid;
    border-color:#fff transparent;
    /* reduce the damage in FF3.0 */
    display:block;
    width:0;
  }
  /* Variant : left
  ------------------------------------------ */
  /* creates the larger triangle */
  .triangle-border.left:before {
    bottom: 38px;
    left: -24px;
    border-width: 10px 24px 10px 0;
    border-color: transparent black;
  }
  /* creates the smaller  triangle */
  .triangle-border.left:after {
    bottom: 38px;
    left: -22px;
    border-width: 10px 24px 10px 0;
    border-color: transparent #fff;
  }
  .triangle-border p {
    margin: 15px 0px;
  }

  >>> .v-dialog {
    max-width: 70% !important;
  }
</style>