import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import firebase from '@/plugins/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFilePdf, faAddressCard, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFilePdf, faAddressCard, faTimes)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
      router,
      store,
      vuetify,
    firebase,
      render: h => h(App)
    }).$mount('#app');