import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    racisme: '',
    sexisme: '',
    capacitisme:'',
    homophobie:'',
    genre: '',
    vignettes: ['aman', 'arsene', 'chan', 'christine', 'daniela', 'desiree', 'heloise', 'jacques', 'jean-marc', 'lauryn', 'léon', 'marielle', 'miruna', 'olivia', 'pape', 'phil', 'samuel', 'sandrine', 'selim', 'shamarima', 'sotun', 'stefano', 'stella', 'tarek', 'yvonne', 'zara'],
    vignette:'',
    seen: false,
    text_msg: 'ok',
    user: null,
    error: null,
  },
  getters: {
    racismeNote(state) {
      return state.racisme;
    },
    sexismeNote(state) {
      return state.sexisme;
    },
    capacitismeNote(state) {
      return state.capacitisme;
    },
    homophobieNote(state) {
      return state.homophobie;
    },
    genreNote(state) {
      return state.genre;
    },
    vignetteRandom(state) {
      return state.vignette;
    },
    seenState(state){
      return state.seen;
    },
    text_msg_state(state){
      return state.text_msg;
    },
    getUser(state) {
      return state.user;
    },
    isUserAuth(state) {
      return !!state.user;
    },
    getError(state) {
      return state.error;
    },
  },
  mutations: {
    newVignette(state,msg){
      state.vignette = this.state.vignettes[Math.floor(Math.random() * this.state.vignettes.length)];
      state.seen = !this.state.seen;
      state.text_msg = msg;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
  },
  actions: {
  },
  modules: {
  }
})