import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#31D1FF',
                secondary: '#F16D0B',
                accent: '#02B454'
            }
        }
    }
})